import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import {
  HeatmapEChartsOption,
  KeysIncisionsHeatmap,
  SeriesDataType,
  SqlDependencyHeatmapInterface,
} from 'modules/visualisations/Heatmap/visualisation/types';
import {
  DeleteFiltersForDrillDownInterface,
  getAxisNameLocation,
  getNameIndicatorTextPadding,
} from 'modules/visualisations/common/constants';
import { ThemeColorsInterface } from 'store/reducers/themes/types';
import {
  defaultFictionalData,
  getVisualisationAstParts,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import {
  ActiveIncisionIdInterface,
  AxisSettingsInterface,
  DefaultIncisionInterface,
  EventsSettingsInterface,
  ExtendedSettingsInterface,
  GradientByType,
  HeatmapDataSettings,
  HeatmapIndicatorInterface,
  IncisionSelectItemInterface,
  IndicatorSelectItemInterface,
  PositionValueType,
  VisualisationValuesInterface,
  VisualMapSettingsInterface,
} from 'store/reducers/visualisations/types';
import { BarEChartsOption, ConfigWithGridDimensionsInterface, GridDimensionsInterface } from 'types/echarts';
import { IdInterface, PositionSettingType, StartAndEndInterface } from 'types/store';
import { AbsolutePositionType } from 'types/styles';
import { calculateGridDimension, getLengthOfGraphicString, initialDimensions } from 'utils/generateConfigGraphic';
import { useSelector } from 'react-redux';
import { getVisualisationDataById } from 'store/reducers/visualisations/getters';
import { useEffect, useMemo } from 'react';
import { getRandomInt, replaceEmptyValues } from 'utils/utils';
import { VisualisationNormalizedValuesParams } from 'modules/visualisations/hooks/visualisationNormalizedValues';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { setAstOfVisualisationActions } from 'store/reducers/ast/actions';
import Snackbar from 'services/Snackbar';
import { updateHeatmapDataSettingsById } from 'store/reducers/visualisations/actions';
import { SqlVisualisationProps } from 'modules/visualisations/hooks/sqlVisualisation';
import { disableFilterByIdAction } from 'store/reducers/filters/actions';
import { store } from 'store';
import isUndefined from 'lodash/isUndefined';
import { ErrorMessageInterface, NullableValue } from 'types/global';
import { useLoadGetData } from 'modules/visualisations/hooks/useLoadData';

const dispatch = store.dispatch;

export const getHeatmapData = (
  { indicators }: Pick<HeatmapDataSettings, 'indicators'>,
  activeHorizontalIncision: DefaultIncisionInterface,
  activeVerticalIncision: DefaultIncisionInterface,
  visualisationValues: VisualisationValuesInterface,
) => {
  const indicator = indicators?.[0];

  const incisionXAxis = activeHorizontalIncision;
  const incisionYAxis = activeVerticalIncision;

  if (!incisionXAxis || !incisionYAxis || !indicator) {
    return { data: {}, activeIncisionSum: {}, maxIndicatorSum: {}, minIndicatorSum: {} };
  }

  const {
      name: nameIndicator,
      fieldName: fieldNameIndicator,
      settings: { nameFromDatabase: nameFromDatabaseIndicator },
    } = indicator,
    {
      name: nameXAxis,
      fieldName: fieldNameXAxis,
      settings: { nameFromDatabase: nameFromDatabaseXAxis },
    } = incisionXAxis,
    {
      name: nameYAxis,
      fieldName: fieldNameYAxis,
      settings: { nameFromDatabase: nameFromDatabaseYAxis },
    } = incisionYAxis;

  const indicatorFieldName = getVisualisationFieldName({
      name: nameIndicator,
      fieldName: fieldNameIndicator,
      nameFromDatabase: nameFromDatabaseIndicator,
    }),
    incisionXAxisFieldName = getVisualisationFieldName({
      name: nameXAxis,
      fieldName: fieldNameXAxis,
      nameFromDatabase: nameFromDatabaseXAxis,
    }),
    incisionYAxisFieldName = getVisualisationFieldName({
      name: nameYAxis,
      fieldName: fieldNameYAxis,
      nameFromDatabase: nameFromDatabaseYAxis,
    });

  const indicatorsValues = visualisationValues[indicatorFieldName] || [],
    incisionXAxisValues = visualisationValues[incisionXAxisFieldName] || [],
    incisionYAxisValues = visualisationValues[incisionYAxisFieldName] || [];

  const uniqueIncisionXAxisValues = [...new Set(incisionXAxisValues as (string | number)[])];
  const uniqueIncisionYAxisValues = [...new Set(incisionYAxisValues as (string | number)[])];

  const incisionValue: VisualisationValuesInterface = {
    [incisionXAxisFieldName]: uniqueIncisionXAxisValues as string[] | number[],
    [incisionYAxisFieldName]: uniqueIncisionYAxisValues as string[] | number[],
  };

  const activeIncisionSum = (incisionValue[incisionYAxisFieldName] as string[])?.reduce<Record<string, number>>(
    (result, key, index) => {
      const value = indicatorsValues?.[index] || 0;

      return {
        ...result,
        [key]: typeof value === 'string' ? 1 : value,
      };
    },
    {},
  );

  const seriesData: SeriesDataType = [];
  let maxIndicatorSumValue = 0;
  let minIndicatorSumValue = 0;

  if (incisionXAxisValues.length > 0 && incisionYAxisValues.length > 0 && indicatorsValues.length > 0) {
    for (let i = 0; i < incisionXAxisValues.length; i++) {
      const incisionXAxisValue = incisionXAxisValues[i] as string | number;
      const incisionYAxisValue = incisionYAxisValues[i] as string | number;
      const xValue = incisionXAxisValue ?? uniqueIncisionXAxisValues?.indexOf(incisionXAxisValue);
      const yValue = incisionYAxisValue ?? uniqueIncisionYAxisValues?.indexOf(incisionYAxisValue);

      const value = indicatorsValues[i] as string | number;

      maxIndicatorSumValue = maxIndicatorSumValue > +value ? maxIndicatorSumValue : Number(value);
      minIndicatorSumValue = minIndicatorSumValue < +value ? minIndicatorSumValue : Number(value);

      seriesData.push([yValue, xValue, value]);
    }
  }

  const indicatorsValue: Record<string, SeriesDataType> = {
    [indicatorFieldName]: seriesData,
  };

  const maxIndicatorSum: Record<string, number> = {
    [indicatorFieldName]: maxIndicatorSumValue,
  };

  const minIndicatorSum: Record<string, number> = {
    [indicatorFieldName]: minIndicatorSumValue,
  };

  return { data: { ...incisionValue, ...indicatorsValue }, activeIncisionSum, maxIndicatorSum, minIndicatorSum };
};

interface AxisConfigIndicator {
  min?: number;
  max?: number;
  maxStringLength?: number;
}

interface GetIndicatorAxisConfigParams extends AxisConfigIndicator {
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  activeThemeSchema: ThemeColorsInterface;
  mainIndicator: AxisConfigIndicator;
}

export const getIndicatorAxisConfig: (params: GetIndicatorAxisConfigParams) => HeatmapEChartsOption['yAxis'] = ({
  axisIndicatorSettings,
  activeThemeSchema,
  mainIndicator,
}) => {
  const {
    isShow,
    showAxis,
    position,
    name,
    label: { isActive: isActiveLabel, value: labelValue, properties },
    stepSize,
    showGrid,
  } = axisIndicatorSettings;

  const { max, min, maxStringLength } = mainIndicator;

  return [
    {
      type: 'value',
      show: isShow,
      position,
      max,
      min,
      name: name.isShow ? name.text : undefined,
      nameLocation: getAxisNameLocation[name.position.type],
      nameTextStyle: {
        padding: getNameIndicatorTextPadding({
          isRotated: false,
          isAutoType: name.type === 'auto',
          positionValue: name.position.value,
          isLeft: position === 'left',
          maxStringLength,
        })[name.position.type],
        align: undefined,
      },
      splitNumber: stepSize.type === 'manual' ? stepSize.value : undefined,
      axisLine: {
        show: showAxis,
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisLabel: {
        show: isActiveLabel,
        margin: isActiveLabel ? labelValue : undefined,
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: properties?.fontSize,
          fontWeight: properties?.fontStyle?.bold ? 'bold' : 'normal',
          fontStyle: properties?.fontStyle?.italic ? 'italic' : 'normal',
        },
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          type: 'dashed',
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisTick: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    },
  ];
};

export const getIndicatorAxisGridDimension: (params: GetIndicatorAxisConfigParams) => GridDimensionsInterface = ({
  axisIndicatorSettings,
  maxStringLength = 0,
}) => {
  const {
    isShow,
    position,
    name,
    label: { isActive: isActiveLabel },
  } = axisIndicatorSettings;

  if (!isShow) return initialDimensions;

  const nameGridShift = 20 + getLengthOfGraphicString(name.text),
    baseStringLength = 20;

  const baseGrid = {
    ...initialDimensions,
    [position]: baseStringLength + (isActiveLabel ? maxStringLength : 0),
  };

  const namePositionGridDimensions = {
    'flex-start': {
      ...initialDimensions,
      bottom: 20,
      left: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift : nameGridShift / 2,
    },
    center: position === 'left' ? { ...initialDimensions, left: 20 } : { ...initialDimensions, right: 10 },
    'flex-end': {
      ...initialDimensions,
      top: 20,
      left: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
    },
  };

  const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

  return calculateGridDimension([baseGrid, namePositionGrid]);
};

export const getIndicatorAxisWithGridDimensions: (
  params: GetIndicatorAxisConfigParams,
) => ConfigWithGridDimensionsInterface<HeatmapEChartsOption['yAxis']> = (params) => ({
  config: getIndicatorAxisConfig(params),
  gridDimensions: getIndicatorAxisGridDimension(params),
});

export const getBarGraphGridDimensions = (indicators: HeatmapIndicatorInterface[]) => {
  let isActiveGrid = false;

  indicators.forEach(
    ({
      settings: {
        showValue: { isShow, position },
      },
    }) => {
      if (!isActiveGrid) {
        isActiveGrid = isShow && position === 'outside';
      }
    },
  );

  const shiftPosition = 'top';

  return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
};

export const defaultGridDimension: GridDimensionsInterface = {
  left: 70,
  right: 40,
  top: 20,
  bottom: 20,
};

interface MaxAndMinValueInterface {
  maxValue: number;
  minValue: number;
}

interface GetLegendConfigParams {
  visualMapSettings: VisualMapSettingsInterface;
  defaultColor: string;
  typeLegend: GradientByType;
  startAndEnd: StartAndEndInterface;
  colors: string[];
  maxAndMinValue: MaxAndMinValueInterface;
}

export const getLegendConfigWithGridDimensions: (params: {
  typeLegend: GradientByType;
  defaultColor: string;
  maxAndMinValue: MaxAndMinValueInterface;
  startAndEnd: StartAndEndInterface;
  colors: string[];
  visualMapSettings: ExtendedSettingsInterface;
}) => ConfigWithGridDimensionsInterface<BarEChartsOption['visualMap']> = ({
  defaultColor,
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  visualMapSettings,
}) => ({
  config: getLegendConfig({ visualMapSettings, maxAndMinValue, startAndEnd, typeLegend, colors, defaultColor }),
  gridDimensions: getLegendGridDimension(visualMapSettings),
});

export const getLegendConfig: (params: GetLegendConfigParams) => BarEChartsOption['visualMap'] = ({
  visualMapSettings: { type, isShow, location, position, width, properties },
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  defaultColor,
}) => {
  const legendIsAuto = type === 'auto';
  const isHorizontalLocation = location.type === 'right' || location.type === 'left';
  const { maxValue, minValue } = maxAndMinValue;
  const { start, end } = startAndEnd;
  const isCondition = typeLegend === 'condition';

  const fontWeight = properties.fontStyle.bold ? 'bold' : 'normal',
    fontSize = properties.fontSize,
    fontStyle = properties.fontStyle.italic ? 'italic' : 'normal';

  const typeVisualMap = typeLegend === 'valueSpecificSteps' || typeLegend === 'condition' ? 'piecewise' : 'continuous';

  return {
    min: minValue,
    color: !isCondition ? colors : undefined,
    range: [start, end],
    max: maxValue,
    type: typeVisualMap,
    splitNumber: colors?.length,
    calculable: true,
    show: isCondition ? false : isShow,
    textStyle: {
      color: defaultColor,
      fontSize,
      fontWeight,
      fontStyle,
      overflow: 'breakAll',
      width: isHorizontalLocation ? width - 32 : undefined,
    },
    orient: isHorizontalLocation ? 'vertical' : 'horizontal',
    ...getLegendLocation(legendIsAuto, location.value)[location.type],
    ...(isHorizontalLocation
      ? getVerticalLegendPosition(legendIsAuto, position)
      : getHorizontalLegendPosition(legendIsAuto, position))[position.type],
  };
};

export const getLegendGridDimension: (legendSettings: VisualMapSettingsInterface) => GridDimensionsInterface = ({
  location,
  width,
  isShow,
}) => {
  const dimensionByLocation = { left: width, right: width, bottom: 40, top: 40 };

  return { ...initialDimensions, ...(isShow ? { [location.type]: dimensionByLocation[location.type] } : {}) };
};

export const getLegendLocation: (
  legendIsAuto: boolean,
  location: number,
) => Record<AbsolutePositionType, BarEChartsOption['visualMap']> = (legendIsAuto, location) => ({
  top: {
    top: legendIsAuto ? 'top' : location,
  },
  bottom: {
    bottom: legendIsAuto ? 'bottom' : location,
  },
  right: {
    right: legendIsAuto ? 'right' : location,
  },
  left: {
    left: legendIsAuto ? 'left' : location,
  },
});

export const getVerticalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          top: 'top',
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          top: 'bottom',
        },
      }
    : {
        'flex-start': {
          top: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          bottom: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const getHorizontalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          left: 'left',
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          left: 'right',
        },
      }
    : {
        'flex-start': {
          left: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          right: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const onActiveNextVerticalIncisionIdChange = ({
  id,
  dataSettings,
  events,
  activeIncisionId,
  onChange,
}: IdInterface &
  ActiveIncisionIdInterface & {
    dataSettings: HeatmapDataSettings;
    events: EventsSettingsInterface;
    onChange: ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) => void;
  }) => {
  const { isInfluenceItself, isFiltering } = events.filterSettings;

  if (isFiltering && isInfluenceItself) {
    const indexActiveIncision = dataSettings.verticalIncisions.findIndex(({ id }) => id === activeIncisionId);
    const nextIncision = dataSettings.verticalIncisions[indexActiveIncision + 1];

    nextIncision && onChange({ activeIncisionId: nextIncision.id, id });
  }
};

export const useVisualisationNormalizedValuesForHeatmap = ({
  id,
  dataSettings,
  intervalRandomData,
}: VisualisationNormalizedValuesParams) => {
  const visualisationData = useSelector(getVisualisationDataById(id));

  const visualisationValues = useMemo(
    () => visualisationData?.visualisationValues || {},
    [visualisationData?.visualisationValues],
  );

  const heatMapDataSettings = dataSettings as HeatmapDataSettings;
  const { activeHorizontalIncisionId, activeVerticalIncisionId } = heatMapDataSettings;

  const activeVerticalIncision = useMemo(
    () => heatMapDataSettings.verticalIncisions.find((incision) => incision.id === activeVerticalIncisionId),
    [heatMapDataSettings.verticalIncisions, activeVerticalIncisionId],
  );

  const activeIncision = useMemo(
    () => dataSettings.incisions.find((incision) => incision.id === activeHorizontalIncisionId),
    [dataSettings.incisions, activeHorizontalIncisionId],
  );

  const visualisationNormalizedValues = useMemo<VisualisationValuesInterface>(() => {
    if (dataSettings.isRealData) {
      const emptyValuesMap: Record<string, string> = {};

      heatMapDataSettings.incisions.forEach((incision) => {
        const {
          name,
          fieldName,
          settings: {
            emptyValues: { isEmptyValue, value },
            nameFromDatabase,
          },
        } = incision;
        const nameKey = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        if (isEmptyValue) {
          emptyValuesMap[nameKey] = value;
        }
      });

      heatMapDataSettings.verticalIncisions.forEach((verticalIncision) => {
        const {
          name,
          fieldName,
          emptyValues: { isEmptyValue, value },
          settings: { nameFromDatabase },
        } = verticalIncision;
        const nameKey = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        if (isEmptyValue) {
          emptyValuesMap[nameKey] = value;
        }
      });

      const normalizedValues = Object.entries(visualisationValues).reduce<VisualisationValuesInterface>((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value?.map((val: NullableValue) =>
            !isUndefined(emptyValuesMap[key]) ? replaceEmptyValues(val, emptyValuesMap[key]) : val,
          );
        }
        return acc;
      }, {});

      return normalizedValues;
    }

    const firstIncision = dataSettings.incisions?.[0];
    const fictionalData = firstIncision?.fictionalData || defaultFictionalData;

    const incisionsValues =
      activeVerticalIncision &&
      activeIncision &&
      [activeVerticalIncision, activeIncision].reduce(
        (values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
          const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

          return { ...values, [incisionName]: fictionalData };
        },
        {},
      );

    const indicatorsValues = dataSettings.indicators.reduce((values, { fieldName, name, settings: { nameFromDatabase } }) => {
      const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

      return {
        ...values,
        [indicatorName]: fictionalData.map(() => getRandomInt(intervalRandomData?.min || 0, intervalRandomData?.max || 100)),
      };
    }, {});

    return { ...incisionsValues, ...indicatorsValues };
  }, [
    dataSettings.isRealData,
    dataSettings.incisions,
    dataSettings.indicators,
    heatMapDataSettings.verticalIncisions,
    heatMapDataSettings.incisions,
    activeVerticalIncision,
    activeIncision,
    visualisationValues,
    intervalRandomData?.min,
    intervalRandomData?.max,
  ]);

  return { visualisationNormalizedValues };
};

export const useSqlVisualisationForHeatmap = ({
  sqlData: { filterAndGroupRequest },
  dispatch,
  dataSettings,
  id,
  serviceSelectValues = [],
  variablesSelectValues = [],
  imagesSelectValues = [],
  limit,
  sqlRequest,
  modelId,
}: SqlVisualisationProps) => {
  const projectId = useParams<ProjectIdParam>().projectId || '';
  const heatMapDataSettings = dataSettings as HeatmapDataSettings;

  /*TODO: Comment on RLS and wait for the server */
  // const { rls } = useRLS();

  const sqlDataDependency = useMemo<SqlDependencyHeatmapInterface>(() => {
    const { incisions, verticalIncisions, activeVerticalIncisionId, activeHorizontalIncisionId, isRealData, indicators } =
      heatMapDataSettings;

    return {
      incisions: incisions
        .filter(({ id }) => id === activeHorizontalIncisionId)
        .reduce<IncisionSelectItemInterface[]>(
          (result, { id, fieldName, name, settings: { nameFromDatabase, customRequest } }) =>
            fieldName || customRequest
              ? [
                  ...result,
                  {
                    alias: getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
                    selectSql: fieldName || '',
                    customRequest,
                    id,
                  },
                ]
              : result,
          [],
        ),
      verticalIncisions: verticalIncisions
        .filter(({ id }) => id === activeVerticalIncisionId)
        .reduce<IncisionSelectItemInterface[]>(
          (result, { id, fieldName, name, settings: { nameFromDatabase, customRequest } }) =>
            fieldName || customRequest
              ? [
                  ...result,
                  {
                    alias: getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
                    selectSql: fieldName || '',
                    customRequest,
                    id,
                  },
                ]
              : result,
          [],
        ),
      indicators: indicators.reduce<IndicatorSelectItemInterface[]>(
        (result, { id, fieldName, name, settings: { nameFromDatabase }, customRequest, operationType }) =>
          fieldName || customRequest
            ? [
                ...result,
                {
                  alias: getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
                  selectSql: fieldName || '',
                  id,
                  customRequest,
                  operationType,
                },
              ]
            : result,
        [],
      ),
      isRealData,
      serviceSelectValues,
      variablesSelectValues,
      imagesSelectValues,
      id,
      limit,
      filterAndGroupRequest,
    };
  }, [heatMapDataSettings, serviceSelectValues, variablesSelectValues, imagesSelectValues, id, limit, filterAndGroupRequest]);

  /* Update AST Store */
  useEffect(() => {
    const {
      id,
      indicators,
      incisions,
      verticalIncisions,
      activeIncisionId,
      serviceSelectValues,
      variablesSelectValues,
      imagesSelectValues,
      limit,
      filterAndGroupRequest,
    } = sqlDataDependency;

    try {
      const astData = getVisualisationAstParts({
        incisions: [...incisions, ...verticalIncisions],
        activeIncisionId,
        indicators,
        serviceSelectValues,
        variablesSelectValues,
        imagesSelectValues,
        limit,
        filterAndGroupRequest,
      });

      dispatch(setAstOfVisualisationActions({ id, astData }));
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sqlDataDependency)]);

  const { ref } = useLoadGetData({
    sqlRequest,
    dataSettings,
    modelId,
    dispatch,
    id,
    projectId,
  });

  return {
    projectId,
    ref,
    /*TODO: Comment on RLS and wait for the server */
    // rls
  };
};

export const onDeleteFiltersForDrillDownIncisionsHeatmap = ({
  dataSettings,
  enabledFilters,
  lastActiveIncisionId,
  nextActiveIncisionId,
  incisionKey,
}: {
  dataSettings: HeatmapDataSettings;
  incisionKey: KeysIncisionsHeatmap;
} & DeleteFiltersForDrillDownInterface) => {
  const indexLastActiveIncision = dataSettings[incisionKey].findIndex(({ id }) => id === lastActiveIncisionId);
  const indexNextActiveIncision = dataSettings[incisionKey].findIndex(({ id }) => id === nextActiveIncisionId);

  if (indexNextActiveIncision <= indexLastActiveIncision) {
    const incisionsAfter = dataSettings[incisionKey].slice(indexNextActiveIncision).map((incision) => incision.fieldName);

    enabledFilters.map((filter) => {
      if (incisionsAfter.includes(filter.nameSettings.fieldName)) {
        dispatch(disableFilterByIdAction(filter.id));
      }
    });
  }
};

export const onActiveVerticalIncisionIdChange = ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) =>
  dispatch(updateHeatmapDataSettingsById({ dataSettings: { activeVerticalIncisionId: activeIncisionId }, id }));

export const onActiveHorizontalIncisionIdChange = ({ id, activeIncisionId }: ActiveIncisionIdInterface & IdInterface) =>
  dispatch(updateHeatmapDataSettingsById({ dataSettings: { activeHorizontalIncisionId: activeIncisionId }, id }));
