import {
  DefaultIncisionType,
  IncisionRadioSelector,
  IncisionRadioSelectorProps,
} from 'modules/visualisations/components/IncisionRadioSelector';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { IncisionSelectorPositionInterface } from 'store/reducers/visualisations/types';
import { forwardRef, RefObject } from 'react';
import { isUndefined } from 'lodash';

interface SingleIncisionLayoutProps<Incisions extends DefaultIncisionType>
  extends IncisionRadioSelectorProps<Incisions>,
    IncisionSelectorPositionInterface {
  children: ReactChildrenType;
  isDrillDown?: boolean;
}

export const SingleIncisionLayout = forwardRef<HTMLDivElement, SingleIncisionLayoutProps<DefaultIncisionType>>(
  function SingleIncisionLayout<Incisions extends DefaultIncisionType>(
    { children, incisions, isDrillDown, isVisible, ...incisionRadioProps }: SingleIncisionLayoutProps<Incisions>,
    ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
  ) {
    return (
      <FlexContainer ref={ref} gap="10px" flexDirection="column" width="100%" height="100%">
        {(isVisible || isUndefined(isVisible)) && (
          <IncisionRadioSelector isVisible={isVisible} incisions={incisions} {...incisionRadioProps} isDrillDown={isDrillDown} />
        )}
        {children}
      </FlexContainer>
    );
  },
);
