import { TimeIcon } from 'assets/icons/editor';
import { HomeIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

export const ConsoleContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 100%;
  border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_5})`};
`;

interface ClockProps {
  active?: boolean;
}

export const ClockIcon = styled(TimeIcon)<ClockProps>`
  width: 16px;
  height: 16px;

  ${({ active }) =>
    active &&
    css`
      path {
        stroke: var(${ColorVarsEnum.Accent}) !important;
      }
    `}
`;

export const ScheduleIcon = styled(HomeIcon)`
  width: 16px;
  height: 16px;
`;

export const UnfoldingButton = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  padding: 6px 12px;
  background-color: var(${ColorVarsEnum.Level_5_application});
  border: 3px solid var(${ColorVarsEnum.Level_5_application});
  align-items: center;
  border-radius: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(${ColorVarsEnum.Level_1});
    opacity: 0.07;
    border-radius: inherit;
    z-index: -1;
  }
`;

export const UnfoldingBlock = styled.div`
  position: relative;
  transition: 0.5s;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    z-index: 0;
  }

  &:hover ${UnfoldingButton} {
    opacity: 1;
    visibility: visible;
  }
`;
