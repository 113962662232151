import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { HeatmapElementDesignSettingsInterface, HeatmapIndicatorInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import SubSection from 'shared/ui/subSection';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { GradientColorBySpecificSettings } from 'modules/settingsContainer/common/GradientColorBySpecificSettings';
import { colorElementTitle } from 'constants/global';

type HeatmapElementDesignSettingsProps = OnValueChange<HeatmapElementDesignSettingsInterface> & {
  indicators: HeatmapIndicatorInterface[];
};

export const HeatmapElementDesignSettings = ({ value, indicators, onChange }: HeatmapElementDesignSettingsProps) => {
  const { defaultColor } = useColorValues();

  const { gradientElementColorSettings, colorValueColorSettings, properties } = value;

  return (
    <MainContainerSettings titleText="Оформление элементов">
      <SubSection>
        <GradientColorBySpecificSettings
          titleMainContainerText={colorElementTitle}
          isMainContainerSettings
          disabledComponentStepsValue
          disabledComponentByGradientValue
          disabledComponentByValue
          indicators={indicators}
          value={gradientElementColorSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, gradientElementColorSettings: { ...gradientElementColorSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) =>
              onChange({ ...value, gradientElementColorSettings: { ...gradientElementColorSettings, elementColor } })
            }
            value={gradientElementColorSettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </GradientColorBySpecificSettings>
      </SubSection>

      <SubSection>
        <PropertiesSettings
          title="Свойства текста значений"
          value={properties}
          onChange={(properties) => onChange({ ...value, properties })}
          disabledComponentBySpecificValue
          isMainContainerSettings
          isMainContainerColorSettings
          disabledOpacity
          disabledFontColorByBlock
          disabledFontColorBy
          disabledBackgroundColorBy
          disabledComponentColorByValue
          disabledPadding
          disabledLineHeight
          disabledUnderline
          disabledLetterSpacing
        />

        <GradientColorBySpecificSettings
          isMainContainerSettings
          disabledComponentStepsValue
          disabledComponentBySpecificSteps
          disabledComponentByGradientValue
          disabledComponentBySpecificGradientValue
          disabledComponentByValue={false}
          titleMainContainerText="Цвет значений"
          indicators={indicators}
          value={colorValueColorSettings.elementColorBy}
          onChange={(elementColorBy) =>
            onChange({ ...value, colorValueColorSettings: { ...colorValueColorSettings, elementColorBy } })
          }
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(elementColor) =>
              onChange({ ...value, colorValueColorSettings: { ...colorValueColorSettings, elementColor } })
            }
            value={colorValueColorSettings.elementColor}
            defaultActivatorBackgroundColor={defaultColor}
          />
        </GradientColorBySpecificSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
