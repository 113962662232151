import { defaultScrollbarMixin } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { loaderSize } from 'modules/visualisations/Table/visualisation/constants';
import styled, { css } from 'styled-components';
import { PositionSettingType, TextAlignSettingType } from 'types/store';
import { hexToRgb, isSafari } from 'utils/utils';

export const TanStackTableStyled = styled.table`
  width: max-content;
`;

export const tableCellItem = 'table_cell_item';

export const Observe = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 2px;
  bottom: 150px;
`;

export interface ImageInTableProps {
  maxWidth?: number;
  maxHeight?: number;
}

export const ImageInTable = styled.img<ImageInTableProps>`
  max-height: 100px;
  max-width: 100px;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
`;

export interface ImageBlockTableProps extends TableTextBodyProps {
  horizontalAlignment?: PositionSettingType;
  marginBottom?: string;
  marginTop?: string;
  margin?: string;
}

export const ImageBlockTable = styled.div<ImageBlockTableProps>`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ horizontalAlignment }) => horizontalAlignment && `justify-content: ${horizontalAlignment}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
`;

export const RowLoader = styled.div`
  display: table-row;
  position: relative;
  height: 60px;

  div {
    position: absolute;
    left: calc(50% - ${loaderSize});
    top: 12px;
  }
`;

const backgroundColorMixin = css`
  content: '';
  position: absolute;
  background-color: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0.05;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
`;

const stickyMixin = (isBottom?: boolean) => css`
  position: ${() => (isSafari() ? 'relative' : 'sticky')};
  z-index: 3;
  ${isBottom ? 'bottom' : 'top'}: 0;
  background-color: var(${ColorVarsEnum.Level_5_application});
`;

export const TableRow = styled.tr`
  position: relative;
`;

export const TotalRow = styled.tr`
  height: 100%;
  position: relative;
  ${stickyMixin(true)};

  :after {
    ${backgroundColorMixin};
    opacity: 0.8;
    background: ${`var(${ColorVarsEnum.Level_5_application})`};
  }
`;

interface TableHeaderProps extends TableCellProps {
  width?: number | string;
  justifyContent?: string;
  padding?: string;
}

export const TableHeader = styled.th<TableHeaderProps>`
  position: relative;
  font-weight: bold;
  text-align: center;
  gap: 4px;
  align-items: center;

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
  ${({ width }) => width && `width: ${width}px;`};
`;

interface TableTextBodyProps {
  justifyContent?: PositionSettingType;
  alignItems?: PositionSettingType;
  padding?: string;
  textAlign?: TextAlignSettingType;
}

export const TableTextBody = styled.div<TableTextBodyProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  ${({ padding }) => padding && `padding: ${padding};`};
`;

export const AbsoluteColorBlock = styled.div<TableCellProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
  ${({ opacity }) => opacity && `opacity: ${opacity}%`};
`;

interface TableCellProps {
  backgroundColor?: string | null;
  color?: string | null;
  opacity?: number;
}

export const TableCell = styled.td<TableCellProps>`
  position: relative;
  z-index: 2;
  height: inherit;

  ${({ backgroundColor, opacity }) => backgroundColor && `background-color: rgba(${hexToRgb(backgroundColor)}, ${opacity}%)`};
`;

export const TableHead = styled.thead`
  ${stickyMixin()};
  z-index: 5;

  ${TableRow} {
    :after {
      ${backgroundColorMixin};
      top: 0;
      opacity: 0.8;
      background: ${`var(${ColorVarsEnum.Level_5_application})`};
    }
  }
`;

export const TableBody = styled.tbody`
  position: relative;

  ${TableRow} {
    :hover {
      ${TableCell} {
        cursor: pointer;
        z-index: 1;

        :after {
          ${backgroundColorMixin};

          opacity: 0.1 !important;
        }
      }
    }
  }
`;

interface BeatInterface {
  isBeat: boolean;
  color: string | null;
  countUnit: number;
}

interface StyledTableWrapperProps {
  showBackground: boolean;
  isAdaptive: boolean;
  verticalLine: BeatInterface;
  horizontalLine: BeatInterface;
  externalBeat: BeatInterface;
  beatColumnLine: boolean;
  beatHeaderBackground: boolean;
  beatHeaderLine: BeatInterface;
  beatTotalBackground: boolean;
  beatTotalLine: boolean;
  isTotalTop: boolean;
  isTotalShow: boolean;
  incisionsCount: number;
  countRowsInHeader: number;
}

export const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
  overflow: auto;
  width: 100%;
  height: 100%;

  ${defaultScrollbarMixin};

  .fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }

  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }


  ${({ externalBeat: { isBeat, countUnit, color } }) =>
    isBeat &&
    css`
      border: ${`${countUnit}px` || 0} solid var(${ColorVarsEnum.Level_4_widget});
      border-color: ${color};
    `}
  ${({ showBackground }) =>
    showBackground &&
    css`
      ${TableHead}, ${TotalRow} {
        background-color: var(${ColorVarsEnum.Level_4_widget});
      }
    `}
  ${({ isAdaptive }) =>
    isAdaptive &&
    css`
      ${TanStackTableStyled} {
        width: 100%;
      }
    `}
  ${({ isTotalShow, isTotalTop, beatHeaderLine: { isBeat } }) =>
    !(isTotalShow && isTotalTop && isBeat) &&
    css`
      ${TableHead} {
        ${TableRow} {
          :after {
            top: 0;
          }
        }
      }
    `}
  ${TanStackTableStyled} {
    border-spacing: 0;
    border-collapse: separate;
    color: var(${ColorVarsEnum.Level_4});
    padding: 0;

    ${TotalRow} {
      :last-child {
        ${TableCell} {
          border-bottom: 0;
        }
      }
    }

    ${TableHeader}, ${TableCell} {
      margin: 0;

      ${({ horizontalLine: { isBeat, countUnit, color } }) =>
        isBeat &&
        css`
          z-index: 1;
          border-bottom: ${`${countUnit}px` || 0} solid var(${ColorVarsEnum.Level_5});
          border-bottom-color: ${color};
        `}
      &:not(:last-child) {
        ${({ verticalLine: { isBeat, countUnit, color } }) =>
          isBeat &&
          css`
            z-index: 1;
            border-right: ${`${countUnit}px` || 0} solid var(${ColorVarsEnum.Level_5});
            border-right-color: ${color};
          `}
      }
    }

    ${TableHeader} {
      ${({ horizontalLine: { isBeat, countUnit }, isTotalShow, isTotalTop }) =>
        isBeat &&
        isTotalShow &&
        isTotalTop &&
        css`
          border-bottom: ${`${countUnit}px`} solid var(${ColorVarsEnum.Level_5});
        `}
    }

    span {
      z-index: 2;
    }


    ${({ beatHeaderLine: { isBeat, color, countUnit }, countRowsInHeader }) =>
      isBeat &&
      css`
        ${TableHead} {
          ${TableRow}:nth-child(${countRowsInHeader}) {
            ${TableHeader} {
              border-bottom: ${`${countUnit}px` || 0} solid ${color};
            }
          }
        }
      `}

    ${({ beatHeaderBackground }) =>
      !beatHeaderBackground &&
      css`
        ${TableHead} {
          ${TableRow} {
            :after {
              background-color: transparent;
            }
          }
        }
      `}

    ${({ beatTotalBackground }) =>
      !beatTotalBackground &&
      css`
        ${TotalRow} {
          :after {
            background-color: transparent;
          }
        }
      `}

    ${({ beatTotalLine }) =>
      beatTotalLine &&
      css`
        ${TableBody} {
          ${TotalRow} {
            ${TableCell} {
              box-shadow: 0 -1px 0 0 var(${ColorVarsEnum.Level_4});
            }
          }
        }

        ${TableHead} {
          ${TotalRow} {
            ${TableCell} {
              box-shadow: 0 1px 0 0 var(${ColorVarsEnum.Level_4});
            }
          }
        }
      `}
  }
}`;

export const ButtonWrapper = styled.button<TableCellProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  min-width: 16px;
  height: 16px;
  background-color: unset;
  color: var(${ColorVarsEnum.Level_2});
  border-radius: 2px;
  outline: none;
  border: 1px solid var(${ColorVarsEnum.Level_2});
  ${({ color }) => color && `border-color: ${color};`};

  :focus {
    outline: none;
    border: 1px solid var(${ColorVarsEnum.Level_2});
    ${({ color }) => color && `border-color: ${color};`};
  }
`;
