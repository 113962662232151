import { FlexContainer } from 'styles/FlexContainer';
import { SearchInput } from 'modules/ui/inputs/SearchInput';
import React, { ChangeEvent, useCallback, useDeferredValue, useRef, useState } from 'react';
import { SortFunctions, useSearchEngine } from 'utils/hooks/searchEngine';
import { FlowList, FlowListProps } from 'modules/settingsContainer/FlowList';
import { ListItemType } from 'modules/settingsContainer/FlowList/types';
import { SortOptions } from 'modules/settingsContainer/SearchList/ListFlowSearch/SortOptions';
import { getFlatList, getOriginListFromResult } from '../constants';
import { SearchContainer } from './styles';
import { NoopValueType } from 'types/global';
import { ShortProjectInterface } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI/types';
import { useAppDispatch } from 'store';
import snackbar from 'services/Snackbar';
import { ImportIcon } from 'assets/icons/navigations';
import Button from 'modules/ui/Button';
import { addFlowProjectAction, importProjectAction } from 'store/reducers/projectManager/actions';

export type ListFlowSearchProps = FlowListProps & {
  onChangeShortProject: NoopValueType<ShortProjectInterface>;
  shortProject: ShortProjectInterface;
};

export const ListFlowSearch = ({
  data,
  activeFlowProject,
  openFlowProject,
  onActiveFlow,
  selectedInfoId,
  onOpenInformationModal,
  isDraftFlow,
  isViewer,
  onAddItem,
  onChangeShortProject,
  shortProject,
}: ListFlowSearchProps) => {
  const dispatch = useAppDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState('');
  const deferredSearchText = useDeferredValue(searchText);
  const { sortType, sortOrder } = shortProject;
  const sortFunctions: SortFunctions<ListItemType> = {
    lastModified: (a, b, sortOrder) => {
      const dateA = new Date(String(a.updatedAt)).getTime();
      const dateB = new Date(String(b.updatedAt)).getTime();
      return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
    },
    alphabet: (a, b, sortOrder) => (sortOrder === 'ascending' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)),
  };

  const { filteredList } = useSearchEngine({
    data: data,
    searchString: deferredSearchText,
    getOriginListFromResult,
    getFlatList,
    sortFunctions,
    sortOrder,
    sortType,
    options: { keys: ['name'], threshold: 0.3, findAllMatches: true, shouldSort: true },
  });

  const handleFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        if (file.type === 'application/json') {
          const resProtect = await dispatch(importProjectAction({ file })).unwrap();

          if (resProtect) {
            dispatch(addFlowProjectAction(resProtect));
          }
        } else {
          snackbar.show('Формат не совпадает! Выберите json файл', 'error');
        }
        event.target.value = '';
      }
    },
    [dispatch],
  );

  const handleButtonClick = () => fileInputRef.current?.click();

  return (
    <FlexContainer width="100%" flex="1 1 0" gap="24px" flexDirection="column">
      <SearchContainer>
        <SearchInput
          name="search_field"
          placeholder="Поиск"
          needBackground={false}
          width="360px"
          widthContainer="unset"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText('')}
          useDebounce
        />
        <FlexContainer gap="16px">
          {!isViewer && !isDraftFlow && (
            <>
              <Button text="Импортировать" leftIcon={<ImportIcon />} onClick={handleButtonClick} />
              <input type="file" accept=".json" onChange={handleFileUpload} ref={fileInputRef} style={{ display: 'none' }} />
            </>
          )}
          <SortOptions short={shortProject} onChangeShort={onChangeShortProject} />
        </FlexContainer>
      </SearchContainer>
      <FlowList
        data={filteredList}
        activeFlowProject={activeFlowProject}
        onActiveFlow={onActiveFlow}
        openFlowProject={openFlowProject}
        onOpenInformationModal={onOpenInformationModal}
        selectedInfoId={selectedInfoId}
        isDraftFlow={isDraftFlow}
        isViewer={isViewer}
        onAddItem={onAddItem}
      />
    </FlexContainer>
  );
};
