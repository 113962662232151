import { ComposeOption } from 'echarts/core';
import { DataZoomComponentOption, GridComponentOption, LegendComponentOption, TooltipComponentOption } from 'echarts/components';
import { BarSeriesOption } from 'echarts/charts';
import { IdInterface, IsRealDataInterface, SqlLimitInterface } from 'types/store';
import {
  ActiveIncisionIdInterface,
  DefaultSqlDataInterface,
  ImagesSelectValuesInterface,
  IncisionSelectItemInterface,
  IndicatorSelectItemInterface,
  ServiceSelectValuesInterface,
  VariablesSelectValuesInterface,
} from 'store/reducers/visualisations/types';

export type HeatmapEChartsOption = ComposeOption<
  LegendComponentOption | BarSeriesOption | GridComponentOption | TooltipComponentOption | DataZoomComponentOption
>;

export type SeriesDataType = Array<[number | string, number | string, number | string]>;

export interface SqlDependencyHeatmapInterface
  extends IsRealDataInterface,
    Partial<ActiveIncisionIdInterface>,
    ServiceSelectValuesInterface,
    VariablesSelectValuesInterface,
    ImagesSelectValuesInterface,
    IdInterface,
    DefaultSqlDataInterface {
  incisions: IncisionSelectItemInterface[];
  verticalIncisions: IncisionSelectItemInterface[];
  indicators: IndicatorSelectItemInterface[];
  limit?: SqlLimitInterface;
}

export type KeysIncisionsHeatmap = 'verticalIncisions' | 'incisions';

export enum KeysIncisionsHeatmapEnum {
  VERTICAL_INCISIONS = 'verticalIncisions',
  INCISIONS = 'incisions',
}

export type ElementGlobalColorCallback = {
  alias: string;
  indicatorName: string;
  dataIndex: number;
};
