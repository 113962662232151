import { ColorValuesByThemeType } from 'store/reducers/visualisations/types';

export type CursorType =
  | 'default' // Стандартный курсор (стрелка)
  | 'pointer' // Курсор руки (обычно используется для ссылок)
  | 'crosshair' // Курсор перекрестия
  | 'move' // Курсор для перемещения объектов
  | 'text' // Курсор для выбора текста (буква "I")
  | 'wait' // Курсор песочных часов (загрузка)
  | 'help' // Курсор вопросительного знака
  | 'progress' // Курсор, указывающий на то, что операция выполняется (состояние загрузки)
  | 'not-allowed' // Курсор с перекрестием (не разрешено)
  | 'no-drop' // Курсор, указывающий на то, что действие нельзя выполнить
  | 'all-scroll' // Курсор, указывающий на возможность прокрутки в любом направлении
  | 'col-resize' // Курсор для изменения ширины столбца
  | 'row-resize' // Курсор для изменения высоты строки
  | 'n-resize' // Курсор для изменения высоты от верхнего края
  | 'e-resize' // Курсор для изменения ширины от правого края
  | 's-resize' // Курсор для изменения высоты от нижнего края
  | 'w-resize' // Курсор для изменения ширины от левого края
  | 'ne-resize' // Курсор для изменения размера в диагональном направлении (северо-восток)
  | 'nw-resize' // Курсор для изменения размера в диагональном направлении (северо-запад)
  | 'se-resize' // Курсор для изменения размера в диагональном направлении (юго-восток)
  | 'sw-resize' // Курсор для изменения размера в диагональном направлении (юго-запад)
  | 'zoom-in' // Курсор для увеличения
  | 'zoom-out' // Курсор для уменьшения
  | 'grab' // Курсор, указывающий на возможность захвата объекта
  | 'grabbing'; // Курсор, указывающий на то, что объект захвачен

export interface HeightInterface {
  height: string;
}

export type TopAndBottomType = 'top' | 'bottom';
export type RightAndLeftType = 'right' | 'left';
export type AbsolutePositionType = TopAndBottomType | RightAndLeftType;

export type BorderStyleType = 'dashed' | 'dotted' | 'solid';

export enum TopAndBottomEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface TextStylesInterface {
  fontStyle: {
    bold: boolean;
    italic: boolean;
    underline: boolean;
  };
  fontSize: number;
  letterSpacing: number;
  lineHeight: number;
  fontColor: ColorValuesByThemeType | null;
  backgroundColor: ColorValuesByThemeType | null;
  backgroundOpacity: number;
}
