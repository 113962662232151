import { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultDataSettingsWithActiveIncisionIdInterface, VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { getVisualisationValuesAction } from 'store/reducers/visualisations/actions';
import { useDebouncedCallback } from 'use-debounce';
import { AppDispatch } from 'store';
import { ModelIdInterface, SqlRequestInterface } from 'types/store';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { useInView } from 'react-intersection-observer';
import { trackerSection } from 'modules/workspace/components/WorkAreaSpace/constants';
import { AbortablePromise } from 'types/global';
import useWebSocket from 'utils/hooks/useWebSocket';
import { UPDATE_MESSAGE } from 'constants/global';

interface LoadGetDataProps extends ModelIdInterface, SqlRequestInterface, VisualisationIdInterface {
  dataSettings: DefaultDataSettingsWithActiveIncisionIdInterface;
  dispatch: AppDispatch;
  projectId: string;
}

export const useLoadGetData = ({ sqlRequest, dataSettings, modelId, dispatch, id, projectId }: LoadGetDataProps) => {
  const { height } = useWindowDimensions();

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: `${height}px 0px ${height}px 0px`,
    root: document.querySelector(`.${trackerSection}`),
  });

  const [hasRequestedData, setHasRequestedData] = useState(false);
  const requestRef = useRef<AbortablePromise<void> | null>(null);

  const loadData = useCallback(
    () => {
      if (inView && !hasRequestedData && sqlRequest && dataSettings.isRealData && modelId) {
        const request = dispatch(
          getVisualisationValuesAction({
            sqlRequest,
            projectId,
            visualisationId: id,
            modelId,
          }),
        ) as unknown as AbortablePromise<void>;

        setHasRequestedData(true);
        requestRef.current = request;
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasRequestedData, inView, sqlRequest, dataSettings.isRealData, modelId, dispatch, projectId, id, useWebSocket],
  );

  const debouncedLoadData = useDebouncedCallback(loadData, 300);

  useEffect(() => {
    setHasRequestedData(false);
  }, [sqlRequest, dataSettings.isRealData, modelId]);

  useEffect(() => {
    if (inView && !hasRequestedData) {
      debouncedLoadData();
    }
  }, [inView, hasRequestedData, debouncedLoadData]);

  useWebSocket((message) => {
    try {
      if (message !== UPDATE_MESSAGE) return;

      setHasRequestedData(false);
      debouncedLoadData();
    } catch (error) {
      console.error('Ошибка при обработке сообщения от сокета:', error);
    }
  });

  useEffect(() => {
    return () => {
      debouncedLoadData.cancel();
      if (requestRef.current && requestRef.current.abort) {
        requestRef.current.abort();
      }
    };
  }, [debouncedLoadData]);

  return { requestRef, ref };
};
