import { useEffect } from 'react';
import env from 'config/settings';

enum WebSocketMessageType {
  Close = 'close',
}

const useWebSocket = (onMessage: (message: string) => void): void => {
  useEffect(() => {
    const socket = new WebSocket(env.WEB_SOCKET_CONNECT_URL);

    const handleMessage = (event: MessageEvent) => {
      try {
        const message = event.data;
        onMessage(message);
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    };

    const handleError = (error: Event) => {
      console.log(JSON.stringify(error));
    };

    socket.onopen = () => console.log('Подключились к WebSocket');
    socket.onmessage = handleMessage;
    socket.onerror = handleError;
    socket.onclose = () => console.log('Соединение с WebSocket закрыто');

    const closeConnection = () => {
      if (socket.readyState === WebSocket.OPEN) {
        console.log(JSON.stringify({ type: WebSocketMessageType.Close }));
      }
      socket.close();
    };

    return closeConnection;
  }, [onMessage]);
};

export default useWebSocket;
