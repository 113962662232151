import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getActiveFlowId, getActiveProjectId, getShortProject } from 'store/reducers/projectManager/getters';
import { useNavigate } from 'react-router-dom';
import { goToBoardUrl } from 'constants/Routes';
import { setActiveFlowProjectId } from 'store/reducers/projectManager';
import { MenuItemInterface } from 'modules/ui/menu/ContextMenu/types';
import { useModalState } from 'utils/hooks/modalState';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { useFlowList } from 'components/projectsManager/hook/useFlowLIst';
import {
  addFlowProjectAction,
  createFlowProjectAction,
  deleteByIdDraftProjectAction,
  deleteByIdFlowProjectAction,
  deleteFlowProjectAction,
  exportProjectAction,
  protectProjectAction,
  renameProjectAction,
  updateProjectDraftFlowByIdAction,
  updateProjectFlowByIdAction,
  uploadProjectAvatarAction,
} from 'store/reducers/projectManager/actions';
import { ProjectsSearchUI } from 'modules/ui/ManagerOrAdminComponentsUI/ProjectsSearchUI';
import { InfoProject } from 'components/projectsManager/elements/Projects/ProjectsSearchList/Info';
import { HistoryVersionProject } from 'components/projectsManager/elements/Projects/ProjectsSearchList/HistoryVersionProject';
import { useModalActions } from 'utils/hooks/useModalActions';
import { useRole } from 'utils/hooks/useRole';
import { NameModal } from 'components/projectsManager/Modals/NameModal';
import Snackbar from 'services/Snackbar';
import { SortProject } from 'store/reducers/projectManager/types';
import { InformationBar } from 'modules/ui/ManagerOrAdminComponentsUI/InformationBarUI';
import { Tab } from 'modules/ui/tabs/types';
import { FlexContainer } from 'styles/FlexContainer';
import { loadSortSettings, saveSortSettings } from 'utils/SortSettingsStorage';
import { modalConfigs, SHORT_KEY } from './constants';
import { ModalNameType } from 'components/projectsManager/elements/Projects/ProjectsSearchList/types';
import { copyingProject } from 'components/projectsManager/Modals/CopyProjectModal/constants';
import { moveProject } from 'components/projectsManager/Modals/MoveProjectModal/constants';
import { rollBackProject } from 'components/projectsManager/Modals/RollBackProjectModal/constants';
import { infoProject } from 'components/projectsManager/Modals/InfoProjectModal/constants';
import { AvatarProjectInterface } from 'types/types';

export const ProjectsSearchList = () => {
  const activeProjectId = useSelector(getActiveProjectId);
  const { id: activeFlowId } = useSelector(getActiveFlowId);
  const shortProject = useSelector(getShortProject);
  const { isViewer } = useRole();
  const [sortSettings, setSortSettings] = useState<SortProject>(shortProject);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useModalState();
  const { flowProjectsDataList, flowProjectsLoading, isDraftFlow } = useFlowList({ id: activeFlowId, onClose });
  const { openModal } = useModalActions<ModalNameType>(modalConfigs);

  const [informationProject, setInformation] = useState<{ id: string; name: string } | null>(null);

  const activeProject = useMemo(
    () => flowProjectsDataList?.find(({ id }) => id === activeProjectId),
    [flowProjectsDataList, activeProjectId],
  );
  const activeProjectName = activeProject?.title;
  const isProtected = activeProject?.isProtected || false;

  const openFlowProject = useCallback(
    ({ projectId, pageId }: { projectId: string; pageId: string | null }) => navigate(goToBoardUrl(projectId, pageId || '0')),
    [navigate],
  );

  const onBoardAreaClick = (id: string | null) => {
    dispatch(setActiveFlowProjectId(id));
  };

  const onProtectProject = useCallback(async () => {
    try {
      if (activeProjectId) {
        const resProtect = await dispatch(
          protectProjectAction({ projectId: activeProjectId, isProtected: !isProtected }),
        ).unwrap();

        if (resProtect && activeProject) {
          isDraftFlow
            ? dispatch(updateProjectDraftFlowByIdAction({ flowProject: { ...activeProject, isProtected: !isProtected } }))
            : dispatch(updateProjectFlowByIdAction({ flowProject: { ...activeProject, isProtected: !isProtected } }));
        }
      }
    } catch (error) {
      console.error('Error creating project version:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProjectId, isProtected, isDraftFlow, activeProject]);

  const onRenameProject = useCallback(
    async (title: string) => {
      if (!title) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(title)) {
        return Snackbar.show('Проект с таким именем уже существует', 'error');
      }

      try {
        if (activeProjectId && activeProjectName) {
          const resProtect = await dispatch(renameProjectAction({ id: activeProjectId, name: title })).unwrap();

          if (resProtect && activeProject) {
            isDraftFlow
              ? dispatch(updateProjectDraftFlowByIdAction({ flowProject: resProtect }))
              : dispatch(updateProjectFlowByIdAction({ flowProject: resProtect }));
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProjectId, activeProjectName, activeProject, isDraftFlow, isProtected],
  );

  const onRenameProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Переименовать',
          onConfirm: (title) => onRenameProject(title?.trim() || ''),
          valueInput: activeProjectName,
          isRenamable: true,
          renamableTitle: 'Новое название проекта',
          titleText: 'Переименование проекта',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProjectName, onRenameProject],
  );

  const onDeleteProject = useCallback(
    async () => {
      try {
        if (activeProjectId && activeProjectName) {
          const resProtect = await dispatch(deleteFlowProjectAction(activeProjectId)).unwrap();

          if (resProtect) {
            isDraftFlow
              ? dispatch(deleteByIdDraftProjectAction(activeProjectId))
              : dispatch(deleteByIdFlowProjectAction(activeProjectId));

            onClose();
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProjectId, onClose, activeProjectName, isDraftFlow],
  );

  const onDeleteProjectModal = useCallback(
    async () => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Удаление проекта',
          onConfirm: onDeleteProject,
          subTitleText: `Действительно удалить проект «${activeProjectName}»?`,
          confirmationButtonText: 'Удалить',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onDeleteProject, activeProjectName],
  );

  const onChangeAvatar = useCallback(
    async ({ avatar, projectId }: AvatarProjectInterface) => {
      if (!projectId) return;

      const image = new FormData();
      image.append('avatar', avatar || '');

      const response = await dispatch(uploadProjectAvatarAction({ projectId, avatar: image })).unwrap();

      if (!response) return;

      isDraftFlow
        ? dispatch(updateProjectDraftFlowByIdAction({ flowProject: response }))
        : dispatch(updateProjectFlowByIdAction({ flowProject: response }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDraftFlow],
  );

  const handleOpenModal = useCallback(
    (modalName: ModalNameType) => {
      openModal(modalName, {
        projectId: activeProjectId || '',
        projectName: activeProjectName || '',
        activeProject,
        activeFlowId,
        isDraftFlow,
        flowId: activeFlowId,
        isProtected,
        onChangeAvatar,
      });
    },
    [activeFlowId, onChangeAvatar, activeProject, activeProjectId, activeProjectName, isDraftFlow, isProtected, openModal],
  );

  const menuList: MenuItemInterface[] = useMemo(() => {
    if (isViewer) {
      return [
        {
          title: 'О проекте',
          onClick: () => handleOpenModal('infoProject'),
        },
      ];
    }

    return [
      {
        title: 'Копировать',
        onClick: () => handleOpenModal(copyingProject),
      },
      {
        disabled: isProtected,
        title: 'Переместить',
        onClick: () => handleOpenModal(moveProject),
      },
      {
        disabled: isProtected,
        title: 'Откатить',
        onClick: () => handleOpenModal(rollBackProject),
      },
      {
        title: isProtected ? 'Снять защиту' : 'Защитить',
        onClick: onProtectProject,
      },
      {
        disabled: isProtected,
        title: 'Переименовать',
        onClick: onRenameProjectModal,
      },
      {
        title: 'О проекте',
        onClick: () => handleOpenModal(infoProject),
      },
      {
        disabled: isProtected,
        title: 'Удалить',
        onClick: onDeleteProjectModal,
      },
    ];
  }, [isViewer, handleOpenModal, isProtected, onDeleteProjectModal, onProtectProject, onRenameProjectModal]);

  const exportProject: MenuItemInterface[] | undefined = useMemo(() => {
    if (isViewer) {
      return;
    }

    return [
      {
        title: 'Экспортировать проект',
        onClick: () =>
          activeProjectId &&
          dispatch(
            exportProjectAction({
              projectId: activeProjectId,
              projectName: activeProjectName,
            }),
          ),
      },
    ];
  }, [dispatch, isViewer, activeProjectId, activeProjectName]);

  const onOpenInformationModal = ({ id, name }: { id: string; name: string }) => {
    setInformation({ id, name });
    !isOpen && onOpen();
  };

  const onCloseInformationModal = () => {
    setInformation(null);
    onClose();
  };

  const modelMetaDataList = useMemo(
    () =>
      flowProjectsDataList.map(({ title, id, firstPage, createdAt, updatedAt, isProtected, avatar }) => ({
        items: [],
        title: title,
        type: id,
        meta: firstPage,
        image: avatar,
        createdAt,
        updatedAt,
        isProtected,
      })),
    [flowProjectsDataList],
  );

  const hasName = useCallback(
    (nameFlowProject: string) => flowProjectsDataList?.find(({ title }) => title === nameFlowProject),
    [flowProjectsDataList],
  );

  const onCloseCreateFlowProjectModal = () => dispatch(closeModalAction('createFlowProject'));

  const onCreateFlowProject = useCallback(
    async (title: string) => {
      if (!title) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(title)) {
        return Snackbar.show('Проект с таким именем уже существует', 'error');
      }

      try {
        const resProtect = await dispatch(createFlowProjectAction(title)).unwrap();

        if (resProtect) {
          dispatch(addFlowProjectAction(resProtect));
          openFlowProject({ projectId: resProtect.id, pageId: resProtect?.firstPage });
          onCloseCreateFlowProjectModal();
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName],
  );

  const onCreateProjectModal = useCallback(
    () => {
      dispatch(
        openModalTypedAction({
          Component: NameModal,
          componentProps: {
            onClose: onCloseCreateFlowProjectModal,
            onClick: onCreateFlowProject,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            maxHeight: '168px',
            headerText: 'Создать проект',
          },
          name: 'createFlowProject',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseCreateFlowProjectModal, onCreateFlowProject],
  );

  const onChangeShortProject = useCallback(
    ({ sortOrder, sortType }: SortProject) => {
      setSortSettings({ sortOrder, sortType });

      saveSortSettings({ SHORT_KEY, streamId: activeFlowId, settings: { sortOrder, sortType } });
    },
    [activeFlowId],
  );

  const onCloseMenu = () => {
    dispatch(setActiveFlowProjectId(null));
  };

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Инфо',
          prompt: 'Инфо',
          type: 'text',
          id: 'info',
          Component: () => <InfoProject activeProjectId={informationProject?.id || ''} onChangeAvatar={onChangeAvatar} />,
        },

        {
          content: 'Версии',
          prompt: 'Версии',
          type: 'text',
          id: 'version',
          Component: () => (
            <HistoryVersionProject
              projectId={informationProject?.id || ''}
              projectName={informationProject?.name}
              padding="0"
              flowId={activeFlowId}
            />
          ),
        },
      ].filter(({ Component }) => Component) as Tab[],
    [informationProject?.id, informationProject?.name, onChangeAvatar, activeFlowId],
  );

  useEffect(() => {
    if (activeFlowId) {
      const settings = loadSortSettings({ streamId: activeFlowId, SHORT_KEY });

      if (settings) {
        setSortSettings(settings);
      }
    }
  }, [activeFlowId]);

  return (
    <FlexContainer flexDirection="row" width="100%" height="100Vh">
      <ProjectsSearchUI
        modelMetaDataList={modelMetaDataList || []}
        onCloseMenu={onCloseMenu}
        onAddItem={onCreateProjectModal}
        isViewer={isViewer}
        projectsLoading={flowProjectsLoading}
        activeProjectId={activeProjectId || ''}
        onBoardAreaClick={onBoardAreaClick}
        onOpenInformationModal={onOpenInformationModal}
        menuList={menuList}
        exportProject={exportProject}
        widgetIdInViewerMode={true}
        informationOpenId={informationProject?.id || ''}
        isOpenInformationBar={isOpen}
        onCloseInformationModal={onCloseInformationModal}
        onOpenFlowProject={openFlowProject}
        isDraftFlow={isDraftFlow}
        onChangeShortProject={onChangeShortProject}
        shortProject={sortSettings}
      />
      <InformationBar open={isOpen} onClose={onCloseInformationModal} tabs={tabs} />
    </FlexContainer>
  );
};
