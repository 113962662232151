import { AdminFlows } from 'components/admin/flows';
import { AdminSettings } from 'components/admin/settings';
import React from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import styled from 'styled-components';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';

import { reboot } from 'styles/reboot';
import { css, Global } from '@emotion/react';
import { WorkSpace } from 'modules/workspace';
import { ApplyTheme } from 'styles/ColorVars';
import LoginPage from './components/loginPage';
import { AdminRoute } from 'shared/adminRoute';
import ConsoleSpace from './components/console';
import PrivateRoute from './shared/privateRoute';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { AdminUsers } from 'components/admin/usersPage';
import { echartsTooltipClassName } from 'constants/echarts';
import { SnackbarUtilsConfigurator } from 'services/Snackbar';
import { PersistGate } from 'redux-persist/integration/react';
import { getProjectIdUrl, RoutesURL } from 'constants/Routes';
import { colorPickerClassName } from 'modules/settingsContainer/ColorPicker/constants';
import { ModelsPage } from 'pages/Models';
import { imagePickerClassName } from 'modules/settingsContainer/ImagePicker/constants';
import { ProjectRoure } from 'shared/projectRoure';
import { AppModals } from 'components/AppModals';
import { SELECTOR_MENU } from 'modules/workspace/constans';
import { AdminLicenses } from 'components/admin/licenses';
import { ProjectsManager } from 'components/projectsManager';
import { AdminGroups } from 'components/admin/groups';
import { AdminTasks } from 'components/admin/tasks';
import { AdminActiveDirectories } from 'components/admin/activeDirectories';
import { AdminSources } from 'components/admin/sources';
import { SnackbarProviderContainer } from 'constants/styles';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path={getProjectIdUrl(RoutesURL.model)}
        element={
          <PrivateRoute>
            <ProjectRoure>
              <ModelsPage />
            </ProjectRoure>
          </PrivateRoute>
        }
      />
      <Route
        path={getProjectIdUrl(RoutesURL.console)}
        element={
          <PrivateRoute>
            <ProjectRoure>
              <ConsoleSpace />
            </ProjectRoure>
          </PrivateRoute>
        }
      />
      <Route
        path={`${getProjectIdUrl(RoutesURL.board)}/:pageId`}
        element={
          <PrivateRoute>
            <ProjectRoure>
              <WorkSpace />
            </ProjectRoure>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminLicenses}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminLicenses />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminGroups}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminGroups />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminFlows}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminFlows />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminTasks}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminTasks />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminActiveDirectories}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminActiveDirectories />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminSources}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminSources />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminSettings}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminSettings />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.adminUser}
        element={
          <PrivateRoute>
            <AdminRoute>
              <AdminUsers />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={RoutesURL.projectsList}
        element={
          <PrivateRoute>
            <ProjectsManager />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to={RoutesURL.projectsList} />} />
    </>,
  ),
);

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProviderContainer
            preventDuplicate
            maxSnack={3}
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SnackbarUtilsConfigurator />
            <AppModals />
            <Container className="App">
              <ApplyTheme>
                <RouterProvider router={router} />
              </ApplyTheme>
            </Container>
          </SnackbarProviderContainer>
        </PersistGate>
      </Provider>
      <Global
        styles={css`
          ${reboot};

          body {
            overflow: hidden;
          }

          .no-select {
            user-select: none;
          }

          .SnackbarContainer-root {
            z-index: 2000 !important;
          }

          .${SELECTOR_MENU} {
            /* 1301 - more than MuiModal-root z-index = 1300 (from MUI) */
            /* for Autocomplete */

            &.MuiAutocomplete-popper {
              z-index: 1301;
            }

            /* for Selector */

            .MuiPaper-root {
              z-index: 1301;
            }
          }

          .${colorPickerClassName} {
            .MuiPaper-root {
              /* for ColorPicker */
              z-index: 1301;
            }
          }

          .${imagePickerClassName} {
            .MuiPaper-root {
              /* for ColorPicker */
              z-index: 1301;
            }
          }

          html {
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
          }

          .ace_editor.ace_autocomplete {
            width: 500px;
          }

          .${echartsTooltipClassName} {
            display: none;
            background-color: var(${ColorVarsEnum.Level_3_menu}) !important;
            border-radius: 2px !important;
            border: 1px solid var(${ColorVarsEnum.Level_4}) !important;
            padding: 14px 12px !important;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
            color: var(${ColorVarsEnum.Level_2}) !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 20px !important;
          }

          .MuiPopover-root {
            position: unset !important;
          }

          .MuiPaper-root {
            //position: unset !important;

            .MuiAutocomplete-noOptions {
              background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;
              color: ${`var(${ColorVarsEnum.Level_1})`};
              height: 30px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            li {
              width: 100%;
              color: ${`var(${ColorVarsEnum.Level_1})`};
              transition: all 200ms;
              padding: 8px !important;
              height: 30px;
              background: ${`var(${ColorVarsEnum.Alert})`};
              text-overflow: ellipsis;
              white-space: nowrap;

              div,
              span {
                z-index: 2;
              }
            }

            li:hover {
              background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};

              :before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: ${`var(${ColorVarsEnum.Level_5})`};
                z-index: 1;
              }
            }

            ul {
              cursor: pointer;
              padding: 0;
              max-height: 300px;

              .Mui-focused,
              .MuiAutocomplete-option[aria-selected='true'] {
                background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;
              }

              li {
                color: ${`var(${ColorVarsEnum.Level_1})`};
                transition: all 200ms;
                padding: 8px !important;
                height: 30px;
                background: ${`var(${ColorVarsEnum.Level_3_menu})`};
                text-overflow: ellipsis;
                white-space: nowrap;

                div,
                span {
                  z-index: 2;
                }
              }

              li:hover {
                background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};

                :before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background: ${`var(${ColorVarsEnum.Level_5})`};
                  z-index: 1;
                }
              }

              .Mui-selected {
                color: ${`var(${ColorVarsEnum.Accent})`};
              }
            }

            ::-webkit-scrollbar {
              width: 6px;
              height: 6px;
              transform: rotate(90deg);
              top: 20px;
              right: 20px;
              position: absolute;
            }

            &::-webkit-scrollbar {
              width: 6px;
              background-color: ${`var(${ColorVarsEnum.Level_5})`};
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: ${`var(${ColorVarsEnum.Level_4})`};
            }
          }

          .MuiTooltip-popper {
            z-index: 9999 !important;
          }
        `}
      />
    </>
  );
};

export default App;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
