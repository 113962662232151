import React, { FC, memo, useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { alreadyAddedTitle, filterOptions, options, otherTitle } from 'components/console/elements/SourcesConnection/constants';
import { getActiveSourceId, getSources } from 'store/reducers/sources/getters';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import {
  deleteByIdScriptSourceAction,
  deleteScriptSourceByIdAction,
  updateSourceFileAction,
} from 'store/reducers/sources/actions';
import { SourceDataBaseEditModal } from 'components/console/elements/SourcesConnection/SourceDataBaseEditModal';
import { SourcesList } from 'modules/settingsContainer/SourcesList';
import { TaskStatus } from 'components/console/elements/hooks/useTaskStatuses';
import { SourceFileEditModal } from 'components/console/elements/SourcesConnection/SourceFileEditModal';
import { NoopValueType } from 'types/global';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { loadSQLScriptAction } from 'store/reducers/loadingScript/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { sourceDataBaseEdit } from 'components/console/elements/SourcesConnection/SourceDataBaseEditModal/constants';
import { sourceFileEdit } from 'components/console/elements/SourcesConnection/SourceFileEditModal/constants';
import { useConnection } from 'components/console/elements/hooks/useConnection';
import { MultiSelectType } from 'modules/ui/MultiSelect';
import { SearchSortSelect } from 'components/shared/ui/SearchSortSelect';
import { MultiSelectFilter } from 'components/shared/ui/SearchSortSelect/MultiSelectFilter';
import { multiSelectFilter } from 'components/shared/ui/SearchSortSelect/MultiSelectFilter/constants';
import { useFilteredData } from 'components/console/elements/hooks/useFilteredData';
import { SortType } from './types';
import { AccessSource } from 'components/console/elements/SourcesConnection/AccessSource';
import { accessSource } from 'components/console/elements/SourcesConnection/AccessSource/constants';

type SourcesConnectionProps = {
  loadingStatuses: Record<string, TaskStatus & { loading: boolean }>;
};

export const SourcesConnectionComponent: FC<SourcesConnectionProps> = ({ loadingStatuses }) => {
  const { projectId } = useParams<ProjectIdParam>();
  const dispatch = useAppDispatch();

  const onConnection = useConnection(projectId || '');

  const [searchText, setSearchText] = useState('');
  const [saveMultiFilter, setSaveMultiFilter] = useState<MultiSelectType<string>[]>([]);
  const [sortType, setSortType] = useState<SortType>('ascendingAlphabet');

  const deferredSearchText = useDeferredValue(searchText);

  const { loading: loadingSources, sourcesList } = useSelector(getSources);
  const activeSourceId = useSelector(getActiveSourceId);

  const onCloseDataBaseEditModal = () => dispatch(closeModalAction(sourceDataBaseEdit));
  const onCloseFileEditModal = () => dispatch(closeModalAction(sourceFileEdit));

  const onDelete = useCallback(
    async (connectionId: string) => {
      try {
        if (projectId) {
          const action = await dispatch(deleteScriptSourceByIdAction({ projectId, sourceId: connectionId })).unwrap();
          if (action) {
            await dispatch(deleteByIdScriptSourceAction(connectionId));

            dispatch(closeConfirmationModalAction());
            dispatch(loadSQLScriptAction(projectId));
          }
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, onCloseDataBaseEditModal, onCloseFileEditModal],
  );

  const onEditDataBase = useCallback(
    (id: string) =>
      dispatch(
        openModalTypedAction({
          Component: SourceDataBaseEditModal,
          componentProps: {
            onClose: onCloseDataBaseEditModal,
            id: id,
            projectId: projectId || '',
          },
          modalSettings: {
            position: 'static',
          },
          name: sourceDataBaseEdit,
        }),
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseDataBaseEditModal, projectId],
  );

  const onEditFile = useCallback(
    (id: string) =>
      dispatch(
        openModalTypedAction({
          Component: SourceFileEditModal,
          componentProps: {
            onClose: onCloseFileEditModal,
            id: id,
            projectId: projectId || '',
          },
          modalSettings: {
            position: 'static',
          },
          name: sourceFileEdit,
        }),
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseFileEditModal, projectId],
  );

  const onEditSource = useCallback(
    (id: string, type: string) => {
      const editActions: Record<string, NoopValueType<string>> = {
        file: onEditFile,
        database: onEditDataBase,
      };

      const editAction = editActions[type];
      if (editAction) {
        editAction(id);
      }
    },
    [onEditFile, onEditDataBase],
  );

  const onDeleteModal = useCallback(
    async (id: string) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: 'Действительно удалить данные из проекта?',
          onConfirm: () => onDelete(id),
          titleText: 'Удалить соединение',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onUpdate = useCallback(
    async (id: string) => {
      dispatch(updateSourceFileAction(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseMultiSelectFilter = () => dispatch(closeModalAction(multiSelectFilter));

  const onMultiSelectFilter = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: MultiSelectFilter,
          componentProps: {
            onClose: onCloseMultiSelectFilter,
            onSaveFilter: setSaveMultiFilter,
            saveMultiFilter,
            filterOptions,
          },
          modalSettings: {
            position: 'static',
            headerText: 'Фильтровать по типу подключения',
          },
          name: multiSelectFilter,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseMultiSelectFilter, saveMultiFilter],
  );

  const onCloseAccessSourceModal = () => dispatch(closeModalAction(accessSource));

  const onAccessSourceModal = useCallback(
    (id: string) =>
      dispatch(
        openModalTypedAction({
          Component: AccessSource,
          componentProps: {
            onClose: onCloseAccessSourceModal,
            activeSourceId: id,
          },
          modalSettings: {
            position: 'static',
            headerText: 'Управление доступом',
            width: '768px',
            maxHeight: '590px',
          },
          name: accessSource,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAccessSourceModal],
  );

  const filteredData = useFilteredData({ saveMultiFilter, sortType, sourcesList, deferredSearchText });

  const sourcesWithLoading = useMemo(() => {
    return filteredData.map((source) => {
      const loadingInfo = loadingStatuses ? loadingStatuses[source.id] : undefined;

      return {
        ...source,
        loading: loadingInfo ? loadingInfo.loading : false,
        status: loadingInfo ? loadingInfo.status : '',
        progress: loadingInfo ? loadingInfo.progress : 0,
      };
    });
  }, [filteredData, loadingStatuses]);

  const connectedSources = useMemo(() => sourcesWithLoading.filter(({ selected }) => selected), [sourcesWithLoading]);
  const unconnectedSources = useMemo(() => sourcesWithLoading.filter(({ selected }) => !selected), [sourcesWithLoading]);

  /* TODO: Need to be implemented - onUpdate, onDelete */
  return (
    <FlexContainer flexDirection="column" overflow="hidden" overflowY="auto" height="calc(100vh - 120px)">
      <SearchSortSelect
        defaultSelectedMultiFilterValues="Все типы"
        optionsShort={options}
        onSelectedSort={setSortType}
        selectedSort={sortType}
        selectedMultiFilterValues={saveMultiFilter}
        onChangeSearchText={setSearchText}
        searchText={searchText}
        onOpenMultiFilter={onMultiSelectFilter}
        onClearMultiFilter={() => setSaveMultiFilter([])}
      />
      <SourcesList
        title={alreadyAddedTitle}
        data={connectedSources}
        onConnection={onConnection}
        onUpdate={onUpdate}
        onEdit={onEditSource}
        onDelete={onDeleteModal}
        loading={loadingSources}
        activeSourceId={activeSourceId}
        onAccessSourceModal={onAccessSourceModal}
      />
      <SourcesList
        title={otherTitle}
        data={unconnectedSources}
        onConnection={onConnection}
        disabledOnDelete
        onUpdate={onUpdate}
        onEdit={onEditSource}
        loading={loadingSources}
        activeSourceId={activeSourceId}
        onAccessSourceModal={onAccessSourceModal}
      />
    </FlexContainer>
  );
};

export const SourcesConnection = memo(SourcesConnectionComponent);
