import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SidebarWrapper } from 'modules/settingsContainer/SideBarWrapper';
import { loadSourceFileStatusAction, loadSourcesAction, loadSourcesActiveLoadingAction } from 'store/reducers/sources/actions';
import { useAppDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { getSourcesActiveLoading } from 'store/reducers/sources/getters';
import { closeWizardModalAction, openWizardModalTypedAction } from 'store/reducers/modals/actions';
import { stepDataList } from 'components/console/elements/sideBar/WizardSourceModalType/constants';
import { useLongPolling } from 'utils/hooks/useLongPolling';
import { useTaskStatuses } from 'components/console/elements/hooks/useTaskStatuses';
import { SourcesConnection } from '../SourcesConnection';
import { getBlockVisibilityStore } from 'store/reducers/blockVisibilitySettings/getters';
import { DefaultOpenListEnum } from 'types/global';
import { blockToggleSetting } from 'store/reducers/blockVisibilitySettings';

const Sidebar: FC = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<ProjectIdParam>();
  const sourcesActiveLoadingData = useSelector(getSourcesActiveLoading);
  const blockVisibility = useSelector(getBlockVisibilityStore);

  const fetchStatus = useCallback(
    (taskId: string) => dispatch(loadSourceFileStatusAction(taskId)).unwrap(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loadingStatuses = useTaskStatuses({ tasks: sourcesActiveLoadingData, fetchStatus });

  useLongPolling({ intervalCallback: () => dispatch(loadSourcesActiveLoadingAction()), delay: 10000 });

  useEffect(() => {
    if (projectId) {
      dispatch(loadSourcesAction(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onCloseSourceTypeWizard = () => dispatch(closeWizardModalAction());

  const onOpenSidebar = useCallback(
    () => dispatch(blockToggleSetting(DefaultOpenListEnum.SIDEBAR_CONSOLE)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onOpenAddSource = useCallback(() => {
    dispatch(
      openWizardModalTypedAction({
        stepDataList: stepDataList,
        defaultTitle: 'Новое подключение',
        firstStepComponent: 'sources',
        onClose: onCloseSourceTypeWizard,
        projectId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseSourceTypeWizard, projectId]);

  return (
    <SidebarWrapper
      title="Подключения"
      onClick={onOpenAddSource}
      isShowSidebar={blockVisibility[DefaultOpenListEnum.SIDEBAR_CONSOLE]?.isOpen}
      onOpenSidebar={onOpenSidebar}
    >
      <SourcesConnection loadingStatuses={loadingStatuses} />
    </SidebarWrapper>
  );
};

export default Sidebar;
