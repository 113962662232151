import { DefaultPropertiesInterface, TreeVisualisationType } from 'store/reducers/visualisations/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import {
  defaultColors,
  getLabelPosition,
  getTreeMapData,
  getTreeMapLevels,
} from 'modules/visualisations/Tree/visualisation/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { TreeEChartsOption, TreeNode } from 'modules/visualisations/Tree/visualisation/types';
import { memo, useCallback, useMemo } from 'react';
import { LabelOption } from 'echarts/types/src/util/types';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { echartsTooltipClassName } from 'constants/echarts';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { parseToRgb, rgba } from 'polished';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { useDefaultIndicator } from 'modules/visualisations/hooks/defaultIndicator';
import { defaultTreeDataSettings, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { useProperties } from 'modules/visualisations/hooks/useProperties';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { FlexContainer } from 'styles/FlexContainer';

/*
 An example of using manual focus and jumping to a node using the eCharts api - bad but the only way
const onTestClick = () => {
  const testNode = eChartRef.current._chartsViews[0]._oldTree._nodes[384];
  const series = eChartRef.current._chartsViews[0].seriesModel;
  console.log(testNode);
  eChartRef.current?.dispatchAction({
    type: 'treemapZoomToNode',
    from: series.uid,
    seriesId: series.id,
    targetNode: testNode,
  });
  eChartRef.current?.dispatchAction({
    type: 'treemapRootToNode',
    from: series.uid,
    seriesId: series.id,
    targetNode: testNode,
  });
};*/

export const TreeVisualisationComponent: VisualisationOriginInterface<TreeVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data;

  const {
    visualisationNormalizedValues,
    formattingParams: { formatting },
    ref,
    updateFilter,
    eChartRef,
  } = useVisualisation({
    sqlData,
    id,
    colorsBy: [viewSettings.backgroundSettings.colorSettings.backgroundColorBy],
    dataSettings,
    events,

    limit: dataSettings.limit,
    sqlRequest,
  });
  const { getColorValues, activeThemeSchema } = useColorValues();

  const defaultIncision = useMemo(
    () => dataSettings.incisions?.[0] || defaultTreeDataSettings.incisions[0],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSettings.incisions?.[0]],
  );
  const mainIndicator = useDefaultIndicator({
    indicators: dataSettings.indicators,
    defaultIndicator: defaultTreeDataSettings.indicators[0],
  });

  const colors = useMemo(
    () => getColorValues(defaultIncision.colors) || defaultColors(activeThemeSchema),
    [defaultIncision.colors, activeThemeSchema, getColorValues],
  );

  const treeMapData = useMemo(
    () => getTreeMapData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  const propertiesIncisionNameData = useMemo(
    () =>
      dataSettings.incisions.reduce<Record<string, DefaultPropertiesInterface>>(
        (incision, { name, fieldName, propertiesIncisionName, settings: { nameFromDatabase } }) => {
          const incisionsFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
          incision[incisionsFieldName] = propertiesIncisionName;
          return { ...incision, [name]: propertiesIncisionName };
        },
        {},
      ),
    [dataSettings.incisions],
  );

  const propertiesIncisionNameValueData = useMemo(
    () =>
      dataSettings.incisions.reduce<Record<string, DefaultPropertiesInterface>>(
        (incision, { name, fieldName, propertiesIncisionValue, settings: { nameFromDatabase } }) => {
          const incisionsFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
          incision[incisionsFieldName] = propertiesIncisionValue;
          return { ...incision, [name]: propertiesIncisionValue };
        },
        {},
      ),
    [dataSettings.incisions],
  );

  const propertiesIncisionValueData = useMemo(
    () =>
      dataSettings.incisions.reduce<Record<string, DefaultPropertiesInterface>>(
        (incision, { name, fieldName, propertiesIncisionNameValue, settings: { nameFromDatabase } }) => {
          const incisionsFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
          incision[incisionsFieldName] = propertiesIncisionNameValue;
          return { ...incision, [name]: propertiesIncisionNameValue };
        },
        {},
      ),
    [dataSettings.incisions],
  );

  const propertiesIncisionName = useProperties(propertiesIncisionNameData);
  const propertiesIncisionNameValue = useProperties(propertiesIncisionNameValueData);
  const propertiesIncisionValue = useProperties(propertiesIncisionValueData);

  const treeMapLevels = useMemo(
    () =>
      getTreeMapLevels(
        dataSettings.incisions,
        viewSettings.showBackground,
        activeThemeSchema,
        propertiesIncisionName,
        propertiesIncisionNameValue,
        propertiesIncisionValue,
        getColorValues,
      ),
    [
      dataSettings.incisions,
      viewSettings.showBackground,
      activeThemeSchema,
      propertiesIncisionName,
      propertiesIncisionNameValue,
      propertiesIncisionValue,
      getColorValues,
    ],
  );

  const labelPosition = useMemo<LabelOption['position']>(
    () => getLabelPosition(viewSettings.signatures),
    [viewSettings.signatures],
  );

  const formattingFunction = formatting[mainIndicator.fieldName];

  const showLabel = useMemo<boolean>(() => viewSettings.signatures.isShow, [viewSettings.signatures.isShow]);

  const valueFormatting = useCallback(
    (value: number | undefined) => (formattingFunction ? formatting[mainIndicator.fieldName]?.(value) : value),
    [formatting, formattingFunction, mainIndicator.fieldName],
  );

  const labelFormatter = useCallback(
    (params: CallbackDataParams) => {
      const { name, path, value } = params.data as TreeNode;

      if (!path) {
        return '';
      }
      const formattedValue = mainIndicator.data.settings.isShowValue ? `\n${valueFormatting(value)}` : '';
      const nameFromIncision = viewSettings.showNameIncision ? `\n${path}` : '';

      const arr = [`{name|${name}}{nameFromIncision|${nameFromIncision}}{formattedValue|${formattedValue}}`];

      return arr.join('\n');
    },
    [mainIndicator.data.settings.isShowValue, valueFormatting, viewSettings.showNameIncision],
  );

  const tooltipSettings = useMemo(
    () => ({
      show: viewSettings.showTips,
      className: echartsTooltipClassName,
      formatter: (params: CallbackDataParams) => {
        const { name, path, value } = params.data as TreeNode;

        if (!path) {
          return '';
        }

        const formattedValue = mainIndicator.data.settings.isShowValue ? valueFormatting(value) : '';
        const nameFromIncision = viewSettings.showNameIncision ? path : '';

        return `<div><div>${name}</div><div>${nameFromIncision}</div><div>${formattedValue}</div></div>`;
      },
    }),
    [viewSettings.showTips, viewSettings.showNameIncision, mainIndicator.data.settings.isShowValue, valueFormatting],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [viewSettings.description, viewSettings.header] });

  const option = useMemo<TreeEChartsOption>(
    () => ({
      tooltip: tooltipSettings,

      color: colors,

      series: [
        {
          name: 'tree',
          type: 'treemap',
          /* Enable zoom and dragging */
          roam: false,
          /* Deep view of data */
          leafDepth: 2,
          /* Treemap position */
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,

          visibleMin: 300,

          label: {
            show: showLabel,
            formatter: labelFormatter,
            position: labelPosition,
            overflow: 'truncate',
            /* Font settings */
            fontSize: 10,
            fontWeight: 400,
            lineHeight: 12,
            color: activeThemeSchema[ColorVarsEnum.Level_1],
            // fontFamily: 'Roboto,sans-serif',
            // fontStyle: 'normal',

            /* Distance from edge */
            // distance: 5,
            /* Rotation */
            // rotate: 20,
          },

          /* Node label */
          upperLabel: {
            show: true,
            position: 'insideLeft',
            formatter: '{b}',
            distance: 0,
            height: 23,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 14,
            color: activeThemeSchema[ColorVarsEnum.Level_2],
            // fontFamily: 'Roboto,sans-serif',
            // fontStyle: 'normal',
          },

          levels: treeMapLevels,
          data: treeMapData,

          /* Breadcrumb settings */
          breadcrumb: {
            /*  Position */
            // left: 0,
            // height: 40,
            itemStyle: {
              color: rgba({
                ...parseToRgb(activeThemeSchema[ColorVarsEnum.Level_5]),
                alpha: 0.8,
              }),
              textStyle: {
                color: activeThemeSchema[ColorVarsEnum.Level_1],
              },
            },
            /* Hover */
            emphasis: {
              itemStyle: {
                color: activeThemeSchema[ColorVarsEnum.Level_1_hover_btn],
              },
            },
          },

          /* Height and Width */
          // width: '30%',
          // height: '30%',

          /* Node treemap ratio */
          // squareRatio: 1.6,

          /* Node settings */
          // itemStyle: {
          //   gapWidth: 1,
          //   borderColor: 'transparent',
          // },

          /* Hover to node settings */
          // emphasis: {
          /* hover behavior */
          // focus: 'ancestor',
          // blurScope: 'series',
          /* Changing settings when hovered */
          // label: {
          //   show: true,
          //   color: 'green',
          // },
          // },

          /* on blur settings */
          // blur: {
          //   label: {
          //     color: 'green',
          //   },
          // },
        },
      ],
    }),
    [tooltipSettings, colors, showLabel, labelFormatter, labelPosition, treeMapLevels, treeMapData, activeThemeSchema],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      const node = params.data as TreeNode;
      if (!node?.children) {
        updateFilter({ selectedValue: params.name, fieldName: node.tableColumn });
      }
    },
    [updateFilter],
  );

  const onEvents = {
    click: onChartClick,
  };

  return (
    <FlexContainer position="relative" width="100%" height="100%" ref={ref}>
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </FlexContainer>
  );
};

export const TreeVisualisation = memo(TreeVisualisationComponent) as VisualisationOriginInterface<TreeVisualisationType>;
