import { MetricWrapper } from 'modules/settingsContainer/common/data/MetricSettings/styles';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { IncisionSettingsProps } from './types';
import useControls from 'utils/hooks/useControl';
import { Button } from 'modules/ui';
import { AddIcon, DeleteIcon } from 'assets/icons/withContainer';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { ExportIcon, ImportIcon } from 'assets/icons/navigations';
import { FlexContainer } from 'styles/FlexContainer';
import { useAppDispatch } from 'store';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ExportDataInProject } from 'components/shared/ExportArrayDataInProject';
import { exportPage, importPage } from './constants';
import { PageInterface } from 'store/reducers/projectPages/types';
import { ExportPagesAction, ImportPagesAction, loadPagesAction } from 'store/reducers/projectPages/actions';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import snackbar from 'services/Snackbar';
import { ImportDataInProject } from 'components/shared/ImportPages';
import { Divider } from 'modules/workspace/components/PagesModal/styles';

export const MetricListSettings = <Metric extends VisualisationIdInterface & PageInterface>({
  metrics,
  metricRender,
  listRender,
  onDelete,
  onAdd,
  disableAddingMetric,
  titleText,
  addButtonText,
  controls,
  disableDeleteButton = metrics.length === 1,
  maxMetricListHeight,
  activePageId,
  listObjects,
}: IncisionSettingsProps<Metric>) => {
  const { activeMetricId, setActiveMetricId, selectedMetricId, setSelectedMetricId, onCloseFieldSettings, onUnselectMetric } =
    useControls({ controls, activePageId });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { projectId } = useParams<ProjectIdParam>();

  const activeMetric = metrics?.find(({ id }) => id === activeMetricId);
  const selectMetric = metrics?.find(({ id }) => id === selectedMetricId);
  const isEmptyListObjects = !!Object.keys(listObjects).length;

  const onLocalDelete = () => {
    onUnselectMetric();
    onCloseFieldSettings();
    onDelete && onDelete(selectedMetricId);
  };

  const onDropDownClick = () => {
    selectMetric && onUnselectMetric();
    activeMetric && onCloseFieldSettings();
  };

  const onCloseExportPagesModal = useCallback(() => dispatch(closeModalAction(exportPage)), [dispatch]);
  const onCloseImportPagesModal = useCallback(() => dispatch(closeModalAction(importPage)), [dispatch]);

  const onClickExport = useCallback(() => {
    metrics &&
      dispatch(
        openModalTypedAction({
          Component: ExportDataInProject,
          componentProps: {
            onClose: () => onCloseExportPagesModal(),
            onCancel: () => onCloseExportPagesModal(),
            title: 'Выберите страницы для экспорта',
            errorMessage: 'Выберите хотя бы одну страницу для экспорта',
            onChange: (pages) =>
              projectId &&
              dispatch(
                ExportPagesAction({
                  projectId: projectId,
                  data: { pages, exportModels: true },
                }),
              ),
            value: metrics.map(({ name, id }) => ({ name, id, isChecked: false })),
          },
          modalSettings: {
            headerText: 'Экспорт страниц',
            width: '280px',
          },
          name: exportPage,
        }),
      );
  }, [dispatch, metrics, onCloseExportPagesModal, projectId]);

  const handleButtonClick = () => fileInputRef.current?.click();

  const handleFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, newPages: boolean, importModels: boolean) => {
      const file = event.target.files?.[0];

      if (file && projectId && file.type === 'application/json') {
        const resProtect = await dispatch(ImportPagesAction({ projectId, file, newPages, importModels })).unwrap();

        resProtect && dispatch(loadPagesAction(projectId));

        event.target.value = '';
      } else {
        snackbar.show('Некорректный файл! Выберите json файл', 'error');
      }
    },
    [dispatch, projectId],
  );

  const onClickImport = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        openModalTypedAction({
          Component: ImportDataInProject,
          componentProps: {
            onClose: onCloseImportPagesModal,
            onCancel: onCloseImportPagesModal,
            onChange: (newPages, importModels) => handleFileUpload(event, newPages, importModels),
          },
          modalSettings: {
            headerText: 'Импорт страниц',
            width: '320px',
          },
          name: importPage,
        }),
      );
    },
    [dispatch, handleFileUpload, onCloseImportPagesModal],
  );

  return (
    <MainContainerSettings
      onDropDownClick={titleText ? onDropDownClick : undefined}
      titleText={titleText || ''}
      isOpenDefault={true}
    >
      <MetricWrapper maxHeight={maxMetricListHeight}>
        {metricRender({
          metrics: metrics,
          activeMetricId,
          setActiveMetricId,
          selectedMetricId,
          setSelectedMetricId,
        })}
        {!disableAddingMetric && (
          <FlexContainer justifyContent="space-between" padding="0 4px" gap="16px" alignItems="center">
            <FlexContainer alignItems="center" gap="8px">
              <Button text={addButtonText} leftIcon={<AddIcon />} onClick={onAdd} />
              <TooltipIconButton title="Импортировать" leftIcon={<ImportIcon />} onClick={handleButtonClick} />
              <input type="file" accept=".json" onChange={onClickImport} ref={fileInputRef} style={{ display: 'none' }} />
              <TooltipIconButton title="Экспортировать" leftIcon={<ExportIcon />} onClick={onClickExport} />
            </FlexContainer>

            <TooltipIconButton
              title="Удалить страницу"
              leftIcon={<DeleteIcon />}
              onClick={onLocalDelete}
              disabled={!selectedMetricId || disableDeleteButton}
            />
          </FlexContainer>
        )}
        {isEmptyListObjects && <Divider />}
        {selectedMetricId && listRender && (
          <>
            {listRender({
              widgets: listObjects,
              activePageId,
            })}
          </>
        )}
      </MetricWrapper>
    </MainContainerSettings>
  );
};
