import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ListWithSearch } from 'modules/settingsContainer/SearchList/ListWithSearch';
import { ListItem, ListItemComponent } from 'modules/ui/lists/MapList/item/ListItem';
import { SidebarWrapper } from 'modules/settingsContainer/SideBarWrapper';
import { NoopType, NoopValueType } from 'types/global';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { SelectedUniversalLeftBar, UniversalLeftBarListInterface } from 'modules/settingsContainer/UniversalLeftBarList/types';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { startMobileSize } from 'constants/constants';
import { UniversalLeftBarMobileMenu } from 'modules/ui/ManagerOrAdminComponentsUI/UniversalLeftBarMobileMenu';
import { IconType } from 'assets/icons/withContainer';

interface UniversalLeftBarListProps {
  loadingList: boolean;
  data: UniversalLeftBarListInterface[];
  onSelectedItem: NoopValueType<SelectedUniversalLeftBar>;
  titleList?: string;
  isShowSidebar?: boolean;
  onChangeOpenList?: NoopType;
  activeTitle?: string;
  activeId?: string;
  onDeleteItem: NoopValueType<SelectedUniversalLeftBar>;
  onCreateItem?: NoopType;
  IconTitle?: IconType;
  disabledAdditionalField?: boolean;
  additionalFieldTitle?: string;
  additionalFieldId?: string;
  isViewer?: boolean;
  disabledMobileVersion?: boolean;
  buttonTitle?: string;
  universalLeftBarMarginBottom?: string;
  backgroundColorSidebar?: string;
  height?: string;
  buttonMinHeight?: string;
  isShowMoveTo?: boolean;
  onUpClick?: NoopValueType<string>;
  onDownClick?: NoopValueType<string>;
}

export const UniversalLeftBarList: FC<UniversalLeftBarListProps> = ({
  buttonTitle = 'Создать поток',
  data,
  activeTitle,
  activeId,
  titleList,
  onChangeOpenList,
  isShowSidebar = true,
  loadingList,
  onSelectedItem,
  isShowMoveTo,
  disabledAdditionalField = false,
  additionalFieldId,
  additionalFieldTitle = 'Черновики',
  onCreateItem,
  onDeleteItem,
  isViewer = false,
  IconTitle,
  disabledMobileVersion = false,
  universalLeftBarMarginBottom,
  height,
  backgroundColorSidebar,
  onUpClick,
  onDownClick,
  buttonMinHeight,
}) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {(width > startMobileSize || disabledMobileVersion) && (
        <SidebarWrapper
          title={titleList}
          onOpenSidebar={onChangeOpenList}
          buttonMinHeight={buttonMinHeight}
          marginBottom={universalLeftBarMarginBottom}
          isShowSidebar={isShowSidebar}
          disabledButton={isViewer}
          onClick={onCreateItem}
          buttonTitle={buttonTitle}
          height={height}
          backgroundColorSidebar={backgroundColorSidebar}
        >
          <LoadingOverlay loading={loadingList} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

          {!disabledAdditionalField && additionalFieldId && !isViewer && (
            <>
              <FlexContainer width="100" padding="12px 0">
                <ListItemComponent
                  onChecked={() => onSelectedItem({ id: additionalFieldId, title: additionalFieldTitle })}
                  title={additionalFieldTitle}
                  disabledCheckbox
                  activeSelected={activeId == additionalFieldId}
                />
              </FlexContainer>

              <FlexContainer bottom="0" left="0" width="100%" height="1px" backgroundColor={`var(${ColorVarsEnum.Level_5})`} />
            </>
          )}
          {data.length > 0 && (
            <ListWithSearch
              modelMetaData={data}
              onChecked={({ listId, groupId, type }) => {
                onSelectedItem({ title: listId, id: groupId, groupType: type });
              }}
              onDeleteItem={({ id, title }) => {
                id && void onDeleteItem({ id, title });
              }}
              onUpClick={({ id }) => {
                id && onUpClick && onUpClick(id);
              }}
              onDownClick={({ id }) => {
                id && onDownClick && onDownClick(id);
              }}
              renderItem={({ item, key, onSelectChange, onChecked, onDeleteItem, onUpClick, onDownClick }) => (
                <ListItem
                  key={key}
                  id={item.itemId}
                  title={item.title}
                  disabledCheckbox
                  onChange={onSelectChange}
                  onChecked={onChecked}
                  onDeleteItem={!isViewer ? onDeleteItem : undefined}
                  activeSelected={item.title === activeTitle}
                  onUpClick={onUpClick}
                  onDownClick={onDownClick}
                  isShowMoveTo={isShowMoveTo}
                />
              )}
            />
          )}
        </SidebarWrapper>
      )}

      {!disabledMobileVersion && (
        <UniversalLeftBarMobileMenu
          onSelectedItem={onSelectedItem}
          data={[{ id: additionalFieldId, title: additionalFieldTitle, items: [], type: 'owner' }, ...data]}
          activeId={activeId}
          activeTitle={activeTitle}
          IconTitle={IconTitle}
        />
      )}
    </>
  );
};
