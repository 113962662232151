import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { ReactNode, useCallback, useState } from 'react';
import { Button, Switcher } from 'modules/ui';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { NoopType } from 'types/global';

interface ImportDataInProjectProps {
  onChange: (importModels: boolean, newPages: boolean) => void;
  onCancel: NoopType;
  children?: ReactNode;
}

export const ImportDataInProject = ({ onChange, onCancel, children }: ImportDataInProjectProps) => {
  const [importModels, setImportModels] = useState<boolean>(false);
  const [newPages, setNewPages] = useState<boolean>(false);

  const onClickNext = useCallback(() => {
    onChange(importModels, newPages);
    onCancel();
  }, [importModels, newPages, onCancel, onChange]);

  return (
    <FlexContainer flexDirection="column" gap="16px" width="100%">
      <FlexContainer padding="24px 16px" flexDirection="column" gap="16px">
        <FlexContainer gap="16px">
          <Switcher id="newPages" onClick={() => setNewPages(!newPages)} value={newPages} />
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`}>
            Заменить страницы при совпадении идентификаторов
          </PrimaryTextSpan>
        </FlexContainer>
        <FlexContainer gap="16px">
          <Switcher id="importModels" onClick={() => setImportModels(!importModels)} value={importModels} />
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_2})`}>
            Вставить модели из импортируемых страницы
          </PrimaryTextSpan>
        </FlexContainer>
      </FlexContainer>

      {children && <FlexContainer>{children}</FlexContainer>}
      <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
        <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onCancel} />
        <Button text="Продолжить" iconSize="normal" heightSize="normal" onClick={onClickNext} />
      </FlexContainer>
    </FlexContainer>
  );
};
