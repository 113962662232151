import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import snackbar from 'services/Snackbar';
import {
  addUsersGroupsFlowAccess,
  copyProjectVersionAnother,
  createFlow,
  createFlowProject,
  createProjectVersionAnother,
  deleteFlow,
  deleteFlowAccessGroup,
  deleteFlowAccessUser,
  deleteFlowProject,
  exportProject,
  importProject,
  loadDraftFlow,
  loadFlow,
  loadFlowAccesses,
  loadFlowDraftInfo,
  loadFlowProjectHistoryVersion,
  loadFlowProjectInfo,
  loadFlows,
  loadShortFlow,
  loadUsersGroups,
  moveProject,
  protectProject,
  renameProject,
  restoreHistoryProject,
  updateFlowAccessGroup,
  updateFlowAccessUser,
  updateFlowName,
  uploadProjectAvatar,
} from 'store/reducers/projectManager/api';
import {
  AddUsersGroupsFlowAccessPayload,
  CopyProjectVersionAnotherPayload,
  CreateProjectVersionAnotherPayload,
  DeleteGroupFlowAccessByIdPayload,
  DeleteUserFlowAccessByIdPayload,
  FlowAccessInterface,
  FlowListInterface,
  FlowProjectHistoryVersion,
  FlowProjectInfoInterface,
  FlowShortListInterface,
  FlowsListInterface,
  LoadUsersGroupsPayload,
  MoveProjectPayload,
  ProjectManagerActionsTypes,
  ProtectProjectPayload,
  RenameProjectPayload,
  RestoreHistoryProjectPayload,
  UpdateFlowAccessByIdPayload,
  UpdateFlowByIdPayload,
  UpdateFlowNamePayload,
  UpdateFlowProjectByIdPayload,
  UpdateGroupFlowAccessByIdPayload,
  UpdateUserFlowAccessByIdPayload,
  UploadProjectAvatarPayload,
  UsersGroupsInterface,
} from 'store/reducers/projectManager/types';
import {
  addFlow,
  addFlowProject,
  changeActiveFlowId,
  deleteByIdDraftProject,
  deleteByIdFlow,
  deleteByIdFlowAccess,
  deleteByIdFlowProject,
  setSlice,
  updateDraftFlowProject,
  updateFlowAccess,
  updateFlowProject,
  updateFlows,
} from '.';
import { TState } from 'store/index';
import { getFlow, getFlowAccesses, getFlowDraft, getFlows } from 'store/reducers/projectManager/getters';
import { initialProjectManagerStoreState } from 'store/reducers/projectManager/constants';
import { GroupType } from 'types/store';
import { transformationFileForUpload } from 'utils/utils';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadFlowsAction = createAsyncThunk<FlowsListInterface[], void, { rejectValue: null }>(
  ProjectManagerActionsTypes.LOAD_FLOWS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadFlows();

      return response.data.flowsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowsListInterface[];
    }
  },
);

export const loadFlowAction = createAsyncThunk<FlowListInterface[], string>(
  ProjectManagerActionsTypes.LOAD_FLOW,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadFlow(id);

      return response.data.projectsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ProjectListItemDTO[];
    }
  },
);

export const loadMoveFlowAction = createAsyncThunk<FlowListInterface[], string>(
  ProjectManagerActionsTypes.LOAD_MOVE_FLOW,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadFlow(id);

      return response.data.projectsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ProjectListItemDTO[];
    }
  },
);

export const loadShortFlowAction = createAsyncThunk<FlowShortListInterface[], string>(
  ProjectManagerActionsTypes.LOAD_SHORT_FLOW,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadShortFlow(id);

      return response.data.projectsShortList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowShortListInterface[];
    }
  },
);

export const loadDraftFlowAction = createAsyncThunk<FlowListInterface[], void>(
  ProjectManagerActionsTypes.LOAD_DRAFT_FLOW,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadDraftFlow();

      return response.data.projectsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowListInterface[];
    }
  },
);

export const loadFlowDraftInfoAction = createAsyncThunk<FastBoard.API.FlowResponseDTO['flow'], void>(
  ProjectManagerActionsTypes.LOAD_FLOW_DRAFT_INFO,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadFlowDraftInfo();

      return response.data.flow;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadFlowProjectInfoAction = createAsyncThunk<FlowProjectInfoInterface, string>(
  ProjectManagerActionsTypes.LOAD_FLOW_PROJECT_INFO,
  async (id, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjectInfo(id);

      const { updatedDataAt, isProtected, title, created, createdAt, updated, id: projectId, avatar } = response.data.projectMeta;

      return {
        id: projectId,
        updated: { dateAt: updated?.updatedAt, userName: updated?.user?.name },
        created: { dateAt: created?.createdAt, userName: created?.user?.name },
        createdAt,
        avatar,
        title,
        updatedDataAt,
        isProtected,
      } as FlowProjectInfoInterface;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadFlowProjectHistoryVersionAction = createAsyncThunk<FlowProjectHistoryVersion[], string>(
  ProjectManagerActionsTypes.LOAD_FLOW_PROJECT_HISTORY_VERSION,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadFlowProjectHistoryVersion(projectId);

      const res = response.data.versionHistory;
      return res.map(({ id, ts, user, method }) => ({
        id,
        dateTimeHistory: ts,
        userName: user?.login,
        storageMethod: method,
      })) as FlowProjectHistoryVersion[];
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowProjectHistoryVersion[];
    }
  },
);

export const changeActiveFlowIdAction = createAsyncThunk<void, { flowId: string; title: string; groupType?: GroupType }>(
  ProjectManagerActionsTypes.ACTIVE_FLOW_ID,
  ({ flowId, title, groupType }, { dispatch }) => {
    dispatch(changeActiveFlowId({ id: flowId, title, groupType }));
  },
);

export const deleteByIdFlowAction = createAsyncThunk(
  ProjectManagerActionsTypes.DELETE_BY_ID_FLOW,
  (flowId: string, { dispatch }) => {
    dispatch(deleteByIdFlow({ id: flowId }));
  },
);

export const deleteFlowAction = createAsyncThunk<string, string, { rejectValue: null }>(
  ProjectManagerActionsTypes.DELETE_FLOW,
  async (flowId, { rejectWithValue }) => {
    try {
      const response = await deleteFlow(flowId);
      Snackbar.show('Удалено', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const protectProjectAction = createAsyncThunk<FastBoard.API.ProjectUpdateProtectResponseDTO, ProtectProjectPayload>(
  ProjectManagerActionsTypes.PROTECT_PROJECT,
  async ({ projectId, isProtected }, { rejectWithValue }) => {
    try {
      const response = await protectProject({ projectId, isProtected });
      Snackbar.show(isProtected ? 'Защита включена' : 'Защита выключена', 'success');

      return response.data.projectUpdateProtectMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const renameProjectAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, RenameProjectPayload>(
  ProjectManagerActionsTypes.RENAME_PROJECT,
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await renameProject({ id, name });
      Snackbar.show('Проект переименован', 'success');

      return response.data.project;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const createFlowAction = createAsyncThunk<FastBoard.API.FlowResponseDTO['flow'], string, { rejectValue: null }>(
  ProjectManagerActionsTypes.CREATE_FLOW,
  async (name, { rejectWithValue }) => {
    try {
      const response = await createFlow(name);
      Snackbar.show('Поток создан', 'success');

      return response.data.flow;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const createFlowProjectAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, string, { rejectValue: null }>(
  ProjectManagerActionsTypes.CREATE_FLOW_PROJECT,
  async (name, { rejectWithValue }) => {
    try {
      const response = await createFlowProject(name);

      Snackbar.show('Проект создан', 'success');

      return response.data.project;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const restoreHistoryProjectAction = createAsyncThunk<
  FastBoard.API.IncomingWriteResponseDTO,
  RestoreHistoryProjectPayload,
  { rejectValue: null }
>(ProjectManagerActionsTypes.RESTORE_HISTORY_PROJECT, async ({ projectId, dateTimeHistory }, { rejectWithValue }) => {
  try {
    const response = await restoreHistoryProject({ projectId, dateTimeHistory });
    Snackbar.show('Версия успешно применена', 'success');

    return response.data.projectVersionIncomings;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const createProjectVersionAnotherAction = createAsyncThunk<
  string,
  CreateProjectVersionAnotherPayload,
  { rejectValue: null }
>(
  ProjectManagerActionsTypes.CREATE_PROJECT_VERSION_ANOTHER,
  async ({ projectId, dateTimeHistory, name, flowId, runImport }, { rejectWithValue }) => {
    try {
      const response = await createProjectVersionAnother({ projectId, dateTimeHistory, name, flowId, runImport });
      Snackbar.show('Копия успешно создана', 'success');

      return response.data.projectVersionCreateFromMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const copyProjectVersionAnotherAction = createAsyncThunk<string, CopyProjectVersionAnotherPayload, { rejectValue: null }>(
  ProjectManagerActionsTypes.COPY_PROJECT_VERSION_ANOTHER,
  async ({ projectFromId, projectToId, runImport }, { rejectWithValue }) => {
    try {
      const response = await copyProjectVersionAnother({ projectToId, projectFromId, runImport });
      Snackbar.show('Проект успешно заменен', 'success');

      return response.data.projectVersionIncomings;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const moveProjectAction = createAsyncThunk<string, MoveProjectPayload, { rejectValue: null }>(
  ProjectManagerActionsTypes.MOVE_PROJECT,
  async ({ projectId, flowId }, { rejectWithValue }) => {
    try {
      const response = await moveProject({ projectId, flowId });
      Snackbar.show('Проект успешно перемещен', 'success');

      return response.data.projectTransferToFlowMessage;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowNameAction = createAsyncThunk<FastBoard.API.FlowDTO, UpdateFlowNamePayload>(
  ProjectManagerActionsTypes.UPDATE_FLOW_NAME,
  async ({ flowId, name }, { rejectWithValue }) => {
    try {
      const response = await updateFlowName({ flowId, name });
      Snackbar.show('Название успешно изменено', 'success');

      return response.data.flow;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateFlowByIdAction = createAsyncThunk<void, UpdateFlowByIdPayload>(
  ProjectManagerActionsTypes.UPDATE_FLOW_BY_ID,
  ({ flow }, { dispatch, getState }) => {
    const flows = getFlows(getState() as TState).flowsList.map((value) => (value.id === flow.id ? { ...value, ...flow } : value));

    dispatch(updateFlows(flows));
  },
);

export const updateProjectFlowByIdAction = createAsyncThunk<void, UpdateFlowProjectByIdPayload>(
  ProjectManagerActionsTypes.UPDATE_FLOW_PROJECT_BY_ID,
  ({ flowProject }, { dispatch, getState }) => {
    const flowProjects = getFlow(getState() as TState).flowProjectsList.map((value) =>
      value?.id === flowProject?.id ? { ...value, ...flowProject } : value,
    );

    dispatch(updateFlowProject(flowProjects));
  },
);

export const updateProjectDraftFlowByIdAction = createAsyncThunk<void, UpdateFlowProjectByIdPayload>(
  ProjectManagerActionsTypes.UPDATE_DRAFT_FLOW_PROJECT_BY_ID,
  ({ flowProject }, { dispatch, getState }) => {
    const flowProjects = getFlowDraft(getState() as TState).flowProjectsList.map((value) =>
      value?.id === flowProject?.id ? { ...value, ...flowProject } : value,
    );

    dispatch(updateDraftFlowProject(flowProjects));
  },
);

export const deleteByIdFlowProjectAction = createAsyncThunk(
  ProjectManagerActionsTypes.DELETE_BY_ID_FLOW_PROJECT,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdFlowProject({ id }));
  },
);

export const deleteByIdDraftProjectAction = createAsyncThunk(
  ProjectManagerActionsTypes.DELETE_BY_ID_DRAFT_PROJECT,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdDraftProject({ id }));
  },
);

export const deleteFlowProjectAction = createAsyncThunk<string, string, { rejectValue: null }>(
  ProjectManagerActionsTypes.DELETE_FLOW_PROJECT,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await deleteFlowProject(projectId);
      Snackbar.show('Проект удален', 'success');

      return response;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const addFlowAction = createAsyncThunk<void, FlowsListInterface>(
  ProjectManagerActionsTypes.ADD_FLOW,
  (data, { dispatch }) => {
    dispatch(addFlow(data));
  },
);

export const addFlowProjectAction = createAsyncThunk<void, FlowListInterface>(
  ProjectManagerActionsTypes.ADD_FLOW,
  (data, { dispatch }) => {
    dispatch(addFlowProject(data));
  },
);

export const clearFlowsStore = createAsyncThunk(ProjectManagerActionsTypes.CLEAR_FLOWS, (_, { dispatch }) => {
  dispatch(setSlice(initialProjectManagerStoreState));
});

export const loadUsersGroupsAction = createAsyncThunk<UsersGroupsInterface[], LoadUsersGroupsPayload>(
  ProjectManagerActionsTypes.LOAD_USERS_GROUPS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadUsersGroups(params);

      return response.data.userGroupList as UsersGroupsInterface[];
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as UsersGroupsInterface[];
    }
  },
);

export const addUsersGroupsFlowAccessAction = createAsyncThunk<
  FastBoard.API.FlowAddGroupsAndUsersResponseDTO,
  AddUsersGroupsFlowAccessPayload
>(ProjectManagerActionsTypes.ADD_USER_GROUP_FLOW_ACCESS, async ({ ...params }, { rejectWithValue }) => {
  try {
    const response = await addUsersGroupsFlowAccess(params);

    Snackbar.show('Доступы созданы', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const loadFlowAccessAction = createAsyncThunk<FlowAccessInterface[], string>(
  ProjectManagerActionsTypes.LOAD_FLOW_ACCESSES,
  async (flowId, { rejectWithValue }) => {
    try {
      const response = await loadFlowAccesses(flowId);

      return response.data.flowUsersAndGroupsList as FlowAccessInterface[];
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FlowAccessInterface[];
    }
  },
);

export const changeGroupFlowAccessTypeAction = createAsyncThunk<
  FastBoard.API.FlowGroupResponseDTO,
  UpdateGroupFlowAccessByIdPayload
>(ProjectManagerActionsTypes.CHANGE_GROUP_FLOW_ACCESS_TYPE, async ({ groupId, type, flowId }, { rejectWithValue }) => {
  try {
    const response = await updateFlowAccessGroup({ flowId, type, groupId });

    Snackbar.show('Статус группы изменен', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const changeUserFlowAccessTypeAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  UpdateUserFlowAccessByIdPayload
>(ProjectManagerActionsTypes.CHANGE_USER_FLOW_ACCESS_TYPE, async ({ userId, type, flowId }, { rejectWithValue }) => {
  try {
    const response = await updateFlowAccessUser({ flowId, type, userId });

    Snackbar.show('Статус пользователя изменен', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const deleteGroupFlowAccessTypeAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteGroupFlowAccessByIdPayload
>(ProjectManagerActionsTypes.DELETE_GROUP_FLOW_ACCESS_TYPE, async ({ groupId, flowId }, { rejectWithValue }) => {
  try {
    const response = await deleteFlowAccessGroup({ flowId, groupId });

    Snackbar.show('Доступ группы удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const deleteUserFlowAccessTypeAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteUserFlowAccessByIdPayload
>(ProjectManagerActionsTypes.DELETE_USER_FLOW_ACCESS_TYPE, async ({ userId, flowId }, { rejectWithValue }) => {
  try {
    const response = await deleteFlowAccessUser({ flowId, userId });

    Snackbar.show('Доступ пользователя удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const updateFlowAccessByIdAction = createAsyncThunk<void, UpdateFlowAccessByIdPayload>(
  ProjectManagerActionsTypes.UPDATE_FLOW_ACCESS,
  async ({ flowAccess }, { dispatch, getState }) => {
    const flowAccesses = getFlowAccesses(getState() as TState).flowAccessList.map((value) =>
      value?.id === flowAccess?.id ? { ...value, ...flowAccess } : value,
    );

    dispatch(updateFlowAccess(flowAccesses));
  },
);

export const deleteByIdFlowAccessAction = createAsyncThunk(
  ProjectManagerActionsTypes.DELETE_BY_ID_FLOW_ACCESS,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdFlowAccess({ id }));
  },
);

export const uploadProjectAvatarAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, UploadProjectAvatarPayload>(
  ProjectManagerActionsTypes.UPLOAD_PROJECT_AVATAR,
  async (params, { rejectWithValue }) => {
    try {
      const response = await uploadProjectAvatar(params);

      return response.data.project;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const exportProjectAction = createAsyncThunk<void, { projectId: string; projectName?: string }>(
  ProjectManagerActionsTypes.EXPORT_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { projectId, projectName } = params;
      const { data } = await exportProject({ projectId });

      if (data) {
        const jsonString = JSON.stringify(data);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([jsonString], { type: 'application/octet-stream' }));
        link.download = `${projectName || 'project_fastboard'}.json`;
        link.click();
        window.URL.revokeObjectURL(link.href);

        snackbar.show('Проект успешно экспортирован', 'success');
      }
      return;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const importProjectAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, { file: File }>(
  ProjectManagerActionsTypes.IMPORT_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const uploadFile = transformationFileForUpload({ name: 'file', file: params.file });

      const response = await importProject({ ...params, file: uploadFile });

      if (response) {
        snackbar.show('Проект успешно импортирован', 'success');
      }

      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);
