import React, { memo, useCallback, useMemo } from 'react';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { FormattingInterface, SelectItemInterface, TextVisualisationType } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph, PrimaryTextSpan, PrimaryTextType, TextLink } from 'styles/TextsElements';
import { TextFlexContainer, TextVisualisationContainer } from 'modules/visualisations/Text/visualisation/styles';
import {
  getOverflowStyle,
  onLinkVariable,
  onVariable,
  onVariableLink,
  onVariableText,
  textAlignMapper,
} from 'modules/visualisations/Text/visualisation/constants';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { useFormat } from 'modules/visualisations/hooks/useFormat';
import 'react-gallery-carousel/dist/index.css';
import { addProtocolToLink, FindVariablesAndLinks, findVariablesAndLinks, replaceUndefinedWithEmptyValues } from 'utils/utils';
import {
  TextVariablesType,
  TransformationTextVariablesElementInterface,
  VariablesStyle,
} from 'modules/visualisations/Text/visualisation/types';

export const TextVisualisationComponent: VisualisationOriginInterface<TextVisualisationType> = ({ data, sqlRequest }) => {
  const {
    viewSettings: {
      text: { text },
      position: { positionHorizontal, positionVertically },
      textProperties: { fontSize, fontStyle, padding, lineHeight, fontColor, fontColorBy, opacity, letterSpacing },
      backgroundSettings: {
        colorSettings: { backgroundColorBy },
      },
      showHorizontalScroll,
      showVerticalScroll,
      shadowSettings,
    },
    backgroundImagesSettings: { backgroundImages },

    dataSettings,
    sqlData,
    id,
    events,
  } = data;

  const { getColorValues, defaultThemePalette } = useColorValues();
  const backgroundImagesBy = backgroundImages.imagesSettings.backgroundImagesBy;
  const sqlCondition = backgroundImages.imagesSettings.backgroundImagesBy.byCondition.sqlCondition;
  const alias = backgroundImagesBy.byCondition.alias;

  const variablesSelectValues = useMemo(
    () =>
      dataSettings.variables.reduce<SelectItemInterface[]>(
        (result, { customRequest, name }) =>
          customRequest && name !== '' ? [...result, { alias: name, selectSql: customRequest }] : result,
        [],
      ),
    [dataSettings.variables],
  );

  const imagesSelectValues = useMemo(
    () => (backgroundImagesBy.type === 'condition' && sqlCondition ? [{ alias, selectSql: sqlCondition }] : []),
    [alias, backgroundImagesBy.type, sqlCondition],
  );

  const {
    shadowColorSettings: { shadowColorBy },
  } = shadowSettings;

  const textPropertyDataVariables = useMemo(
    () => dataSettings.variables.map(({ settings: { textPropertiesSettings } }) => textPropertiesSettings.fontColorBy),
    [dataSettings.variables],
  );

  const {
    ref,
    visualisationNormalizedValues,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
    getColorValueByCondition,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    events,
    colorsBy: [fontColorBy, backgroundColorBy, shadowColorBy, ...textPropertyDataVariables],
    variablesSelectValues,
    imagesSelectValues,
    limit: dataSettings.limit,
    sqlRequest,
  });

  const formattingDataVariables = useMemo(
    () =>
      dataSettings.variables.reduce<Record<string, FormattingInterface>>(
        (variable, { name, settings: { formatting } }) => ({
          ...variable,
          [name]: formatting,
        }),
        {},
      ),
    [dataSettings.variables],
  );

  const { formatting } = useFormat(formattingDataVariables);

  const conditionalTextColorValue = useMemo(() => {
    const alias = fontColorBy.byCondition.alias,
      colorFontsSQL = getVisualisationColorsAndImagesData(alias)?.[0];

    return fontColorBy.byCondition.sqlCondition && isColorByCondition(alias)
      ? getColorValueByCondition(alias, colorFontsSQL)
      : undefined;
  }, [
    fontColorBy.byCondition.alias,
    fontColorBy.byCondition.sqlCondition,
    getColorValueByCondition,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
  ]);

  const textColorHex = getColorValues(fontColor);

  const textColor = conditionalTextColorValue || textColorHex || defaultThemePalette[0];

  const primaryTextProps: PrimaryTextType = useMemo(
    () => ({
      fontSize: `${fontSize}px`,
      fontWeight: fontStyle.bold ? 'bold' : 'normal',
      fontStyle: fontStyle.italic ? 'italic' : 'normal',
      lineHeight: `${lineHeight}%`,
      letterSpacing: `${letterSpacing}px`,
      textDecoration: fontStyle.underline ? 'underline' : undefined,
      textAlign: textAlignMapper[positionHorizontal],
      color: textColor,
      width: '100%',
      whiteSpace: 'pre-wrap',
    }),
    [fontSize, fontStyle, lineHeight, letterSpacing, positionHorizontal, textColor],
  );

  const textLinkProps: PrimaryTextType = useMemo(
    () => ({
      ...primaryTextProps,
      color: `var(${ColorVarsEnum.Accent})`,
    }),
    [primaryTextProps],
  );

  const overflowX = useMemo(
    () => getOverflowStyle(showHorizontalScroll, showVerticalScroll),
    [showHorizontalScroll, showVerticalScroll],
  );
  const overflowY = useMemo(
    () => getOverflowStyle(showVerticalScroll, showHorizontalScroll),
    [showHorizontalScroll, showVerticalScroll],
  );

  const targetLink = useMemo(() => {
    const { isActive, openWindowType } = events.goToHref;
    if (isActive) {
      return `_${openWindowType}`;
    }

    return '_blank';
  }, [events.goToHref]);

  const createVariablesStyles = useMemo((): VariablesStyle => {
    const styles: VariablesStyle = {};
    dataSettings.variables.forEach((item) => {
      const { isActiveStyle, isActiveFontColor, fontSize, fontStyle, lineHeight, fontColor, opacity, letterSpacing } =
        item.settings.textPropertiesSettings;

      const conditionalTextColorValue = () => {
        const alias = item.settings.textPropertiesSettings.fontColorBy.byCondition.alias,
          colorFontsSQL = getVisualisationColorsAndImagesData(alias)?.[0];

        return item.settings.textPropertiesSettings.fontColorBy.byCondition.sqlCondition && isColorByCondition(alias)
          ? getColorValueByCondition(alias, colorFontsSQL)
          : undefined;
      };
      const textColorHex = getColorValues(fontColor);
      const textColor = isActiveFontColor ? conditionalTextColorValue() || textColorHex || defaultThemePalette[0] : undefined;

      if (isActiveStyle || isActiveFontColor) {
        styles[item.name] = {
          ...(isActiveStyle && {
            fontSize: `${fontSize}px`,
            fontWeight: fontStyle.bold ? 'bold' : 'normal',
            fontStyle: fontStyle.italic ? 'italic' : 'normal',
            lineHeight: `${lineHeight}%`,
            letterSpacing: `${letterSpacing}px`,
            textDecoration: fontStyle.underline ? 'underline' : undefined,
            opacity: opacity / 100,
            whiteSpace: 'pre-wrap',
          }),
          ...(isActiveFontColor && {
            color: textColor,
          }),
        };
      }

      if (!isActiveStyle) {
        styles[item.name] = {
          ...styles[item.name],
          ...primaryTextProps,
          color: styles[item.name]?.color,
        };
      }

      if (!isActiveFontColor) {
        styles[item.name] = {
          ...styles[item.name],
          color: primaryTextProps.color,
        };
      }
    });
    return styles;
  }, [
    getColorValues,
    getVisualisationColorsAndImagesData,
    getColorValueByCondition,
    primaryTextProps,
    isColorByCondition,
    dataSettings.variables,
    defaultThemePalette,
  ]);

  const variablesSettingsText = useCallback(
    (name: string) =>
      dataSettings.variables.find((variable) => variable.name === name)?.settings.textPropertiesSettings.text.text,
    [dataSettings.variables],
  );

  const transformationTextVariables = useCallback(
    (text: string) => {
      const elements = findVariablesAndLinks(text);

      const elementFunctions: Record<
        TextVariablesType,
        (
          element: FindVariablesAndLinks,
        ) => TransformationTextVariablesElementInterface | TransformationTextVariablesElementInterface[] | null
      > = {
        variable: (element) => {
          const variableResult = onVariable({
            element,
            createVariablesStyles,
            visualisationNormalizedValues,
            formatting,
            origin: element.origin,
            variableSettingsText: variablesSettingsText(element.text),
          });

          return variableResult;
        },
        link: (element) => onVariableLink(element),
        text: (element) => onVariableText(element),
        linkVariables: (element) =>
          onLinkVariable({
            element,
            visualisationNormalizedValues,
            variableSettingsText: variablesSettingsText(element.text),
            createVariablesStyles,
          }),
      };

      return elements
        .map((element) => {
          const processElement = elementFunctions[element.type];
          return processElement ? processElement(element) : null;
        })
        .flat();
    },
    [createVariablesStyles, variablesSettingsText, visualisationNormalizedValues, formatting],
  );

  const transformedText = useMemo(() => {
    const transform = transformationTextVariables(text);
    const variables = dataSettings.variables || [];

    return replaceUndefinedWithEmptyValues(transform, variables);
  }, [text, transformationTextVariables, dataSettings.variables]);

  const renderText = useCallback(
    (value: TransformationTextVariablesElementInterface, index: number) => <span key={index}>{value.text}</span>,
    [],
  );

  const renderVariable = useCallback(
    (value: TransformationTextVariablesElementInterface, index: number) => (
      <PrimaryTextSpan key={index} {...value.style}>
        {value.text}
      </PrimaryTextSpan>
    ),
    [],
  );

  const renderLink = useCallback(
    (value: TransformationTextVariablesElementInterface, index: number) => {
      if (value.text) {
        return (
          <TextLink
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={index}
            {...textLinkProps}
            href={addProtocolToLink(value.text)}
            target={targetLink}
            rel="noreferrer"
            {...value.style}
          >
            {value.description}
          </TextLink>
        );
      }
      return null;
    },
    [textLinkProps, targetLink],
  );

  const renderFunctions = useMemo(
    () => ({
      text: renderText,
      variable: renderVariable,
      link: renderLink,
      linkVariables: renderLink,
    }),
    [renderText, renderVariable, renderLink],
  );

  const renderTransformedText = useCallback(
    (transformedText: (TransformationTextVariablesElementInterface | null)[]) =>
      transformedText.map((value, index) => {
        if (value) {
          const renderFunction = renderFunctions[value.type];
          return renderFunction ? renderFunction(value, index) : null;
        }
      }),
    [renderFunctions],
  );

  return (
    <TextVisualisationContainer overflowY={overflowY} overflowX={overflowX} ref={ref}>
      <TextFlexContainer padding={`${padding?.vertical}px ${padding?.horizontal}px`} justifyContent={positionVertically}>
        <FlexContainer width="100%">
          <FlexContainer opacity={opacity / 100} width="100%" zIndex="1">
            <PrimaryTextParagraph {...primaryTextProps}>{renderTransformedText(transformedText)}</PrimaryTextParagraph>
          </FlexContainer>
        </FlexContainer>
      </TextFlexContainer>
    </TextVisualisationContainer>
  );
};

export const TextVisualisation = memo(TextVisualisationComponent) as VisualisationOriginInterface<TextVisualisationType>;
